/***
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/
import React, { useState } from 'react';
import ClassNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Logo, Button, Icon } from 'components/lib';
import './app.scss';

export function AppNav(props) {
  const [open, setOpen] = useState(false); // Mobile menu state
  const [expanded, setExpanded] = useState(false); // Desktop expanded state
  const [openSubmenu, setOpenSubmenu] = useState(null); // Track open submenu

  const toggleSubmenu = (label) => {
    setOpenSubmenu(label); // Open the clicked submenu
  };

  const renderSubmenu = (submenu, parentLabel) => {
    return (
      submenu.map((subItem, index) => (
        <NavLink
          key={`${parentLabel}-${subItem.label}-${index}`}
          to={subItem.link}
          className="submenu-item"
        >
          {subItem.icon && (
            <Icon
              className="icon"
              image={subItem.icon}
              size={open ? 15 : 18}
              color={open ? 'dark' : 'light'}
            />
          )}
          {subItem.label && <span className="label">{subItem.label}</span>}
        </NavLink>
      ))
    );
  };

  return (
    <nav
      className={ClassNames({
        sidebar: true,
        open: open,
        expanded: expanded,
        [`mobile-${props.type}`]: props.type,
      })}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => {setExpanded(false); setOpenSubmenu(false)}}
    >
      {props.type === 'popup' && (
        <Button
          icon={open ? 'x' : 'menu'}
          color={'dark'}
          size={20}
          className="btn-togglenav"
          action={() => setOpen(!open)}
        />
      )}

      <Logo mark />
      <section className="nav-links">
        {props.items?.map((item) => (
          <React.Fragment key={item.label}>
            <div
              className={`menu-item ${item.submenu && 'has-submenu'}`}
              onMouseOver={() => { item.submenu && toggleSubmenu(item.label)}}
              onClick={() => {item.link ? window.location = item.link : window.location = window.location}}
            >
              {item.icon && (
                <Icon
                  className="icon"
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? 'dark' : 'light'}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
              {item.submenu && expanded && (
                <Icon
                  className="chevron-icon"
                  image= { openSubmenu === item.label ? "chevron-down" : "chevron-right" }
                  size={18}
                  color="light"
                  // click={(e) => toggleSubmenu(item.label)} // Ensure this is correctly passed
                />
              )}
            </div>
            {item.submenu && openSubmenu === item.label && (
              <div className="submenu">
                {renderSubmenu(item.submenu, item.label)}
              </div>
            )}
          </React.Fragment>
        ))}
      </section>


      <section style={{position: 'absolute', bottom: 1}}>
      <section className='nav-links'>
        { props.bottomItems?.map(item => {
          
          if (item.link){
            return (
              <NavLink
                key={ item.label }
                to={ item.link }
                style={{ width: (100/props.items.length) + '%' }}>

                { item.icon &&
                  <Icon
                    className='icon'
                    image={ item.icon }
                    size={ open ? 15 : 18 }
                    color={ open ? 'dark' : 'light' }
                  />
                }
                { item.label &&
                  <span className='label'>
                  { item.label }
                  </span>
                }

              </NavLink>
            );
          }

          return (
            <div key={ item.label } onClick={ item.action }>
              
              { item.icon &&
                <Icon
                  className='icon'
                  image={ item.icon }
                  size={ open ? 15 : 18 }
                  color={ open ? 'dark' : 'light' }
                />
              }
              { item.label &&
                <span className='label'>
                  { item.label }
                </span>
              }
            </div>
          )
        }
        )
        }
        
          </section>
      </section>
    </nav>
  );
}
