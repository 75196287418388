/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import './sub.scss';

export function AccountNav(props){

  const context = useContext(AuthContext);

  return(
    <nav className='subnav'>

      <NavLink exact to='/account/profile' activeClassName='active'>Profile</NavLink>
      <NavLink exact to='/account/password' activeClassName='active'>Password</NavLink>
      <NavLink exact to='/account/2fa' activeClassName='active'>2FA</NavLink>

      { context.permission?.owner &&
        <NavLink exact to='/account/billing' activeClassName='active'>Billing</NavLink> }

      {/* { context.permission?.developer && 
        <NavLink to='/account/apikeys' activeClassName='active'>API Keys</NavLink> } */}

      { context.permission?.admin &&
        <NavLink exact to='/account/users' activeClassName='active'>Users</NavLink> }

      <NavLink exact to='/account/emails' activeClassName='active'>E-mails</NavLink>
      <NavLink exact to='/account/accountants' activeClassName='active'>Accountants</NavLink>
    </nav>
  );
}
