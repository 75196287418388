import { Signup } from 'views/auth/signup/account';
import { SignupPlan } from 'views/auth/signup/plan';
import { SignupUser } from 'views/auth/signup/user';
import { Signin } from 'views/auth/signin';
import { SigninOTP } from 'views/auth/signin/otp';
import { SocialSignin } from 'views/auth/signin/social';
import { ForgotPassword } from 'views/auth/signin/forgotpassword';
import { ResetPassword } from 'views/auth/signin/resetpassword';
import { MagicSignin } from 'views/auth/signin/magic';

const Routes = [
  {
    path: '/master',
    view: Signin,
    layout: 'auth',
    title: 'Sign in to LedgerView'
  },
  {
    path: '/signup',
    view: Signup,
    layout: 'auth',
    title: 'Sign up for LedgerView'
  },
  {
    path: '/signup/plan',
    view: SignupPlan,
    layout: 'auth',
    permission: 'owner',
    title: 'Sign up for LedgerView'
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up for LedgerView'
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Welcome to LedgerView'
  },
  {
    path: '/signin/otp',
    view: SigninOTP,
    layout: 'auth',
    title: 'Enter verification code'
  },
  {
    path: '/signin/social',
    view: SocialSignin,
    layout: 'auth',
    title: 'Completing Sign In'
  },
  {
    path: '/auth/openidconnect',
    view: SocialSignin,
    layout: 'auth',
    title: 'Completing Sign In'
  },
  {
    path: '/magic',
    view: MagicSignin,
    layout: 'auth',
    title: 'Sign In'
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'auth',
    title: 'Forgot Your Password?'
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'auth',
    title: 'Reset Your Password'
  },
]

export default Routes;