import React, { useState, useContext, useEffect } from 'react';
import { AuthContext, Card, useAPI, Button } from 'components/lib';
import { Table } from 'components/lib';
import { NavLink } from "react-router-dom";

export function Rules(props){

  const authContext = useContext(AuthContext);
  
  const [rules, setRules] = useState([]);
  const list = useAPI('/api/auditRule/list');
  
  useEffect(() => {
    if(list?.data?.length) {
      //console.log('list.data.length - ' + list.data.length)
      setRules(list.data);
    }

  }, [list.data]);

  function editRule(data, callback){

    window.location = "ruleDetails/" + data.rule_id;
    // context.modal.show({
    //   title: 'Update Rule: \'' + data.display_name + '\'',
    //   form: {
    //     id: {
    //       type: 'hidden',
    //       value: data.rule_id
    //     },
    //     is_active: {
    //         label: 'Is Active',
    //         type: 'switch',
    //         default: data.is_active,
    //       },
    //   },
    //   buttonText: 'Update',
    //   url: '/api/auditRule/' + data.rule_id,
    //   method: 'PATCH'

    // }, (res) => {

    //   context.notification.show(data.name + ' was updated', 'success', true);
    //   callback(res);

    // });
  }

  return (
      <>
      {/* <nav className='subnav'>
      <NavLink exact to='/app/cleanup/rules' activeClassName='active'>Monitors</NavLink>
      <NavLink exact to='/app/cleanup/index' activeClassName='active'>Clean-Up</NavLink>
      </nav> */}

    {/* <Breadcrumbs items={[
        { name: 'Clean-Up Home', url: '/app/cleanup/index' },
        { name: 'Rules List', url: '#' }
      ]}/> */}
    <Card>
      <div style={{display: 'flex', paddingBottom: 20}}>
      {authContext.hasAccountSetting("edit_rules") === true &&
        <div style={{paddingLeft: '10px', marginTop: '1px', marginLeft: 'auto'}}>
          <Button text='Create Monitor' action={() => window.location = "ruledetails"} />  
        </div>
      }
      </div>
      <Table
        showRowNumbers={true}
        data={ rules }
        show={['is_active', 'display_name', 'short_description']}
        actions={{ 
            edit: editRule,
          }}
      >
      </Table>
    </Card>
    </>
    );
}
