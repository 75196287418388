/***
*
*   TERMS & CONDITIONS
*   Boilerplate terms & conditions – replace the values
*   inside [] with your own text
*
**********/

import React from 'react';
import { Article } from 'components/lib';

export function Terms(props){

  return(

    <Article>

      <h1>Terms &amp; Conditions</h1>
      
<p>Please Read Carefully Before Using This Website</p>
<p>Updated: January 1, 2023</p>

<p>Dwyer Technology, Inc. (hereinafter “Company”) maintains this site. This webpage contains the Terms of Use governing your access to and use of LedgerView (hereinafter the “Website”). If you do not accept these Terms of Use or you do not meet or comply with their provisions, you may not use the Site. Such access and all related hardware, software, and services costs are your sole responsibility and you hereby release Company from any liability for any errors, failures or malfunctions of all such hardware, software and/or services, including any data transferred and modified from the use of the Website.</p>

<p>By creating an account and using this Website, you are agreeing with, and accept all of the terms and conditions contained in this Agreement and the <a href="privacy" target="_blank">Privacy Policy</a>, which includes by reference those terms and conditions expressly set out below. </p>

<p>By your use of this site you agree that: </p>

<p>
  1. You are at least eighteen years old, and <br />
  2. You have read, and fully understand and agree to abide by this Agreement and the documents it incorporates by reference,  <br />
  3. You intend to form a legally binding contract by utilizing or viewing this site, and  <br />
  4. YOU MUST EVALUATE, AND BEAR ALL RISKS ASSOCIATED WITH THE USE OF ANY CONTENT, DATA, AND SERVICES, INCLUDING ANY RELIANCE ON THE ACCURACY, COMPLETENESS, OR USEFULNESS OF SUCH CONTENT, DATA, AND ASSOCIATED SERVICES. The service is provided on an “as is” and “as available” basis. <br />
  5. You understand that Company uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. <br />
  6. You understand that the technical processing and transmission of the service, including your content, may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. <br />
  7. Company does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected. <br />
</p>

<p>If you do not agree to be bound by the terms and conditions of this Agreement, please do not use or request our services through this Website. </p>

<p>Within Company's sole discretion these terms are subject to amendment, change, modification, revision, or termination at any time without advance notice to you. Any and all such changes shall be immediately effective at the time they are posted on the Website and it is your (the user's) duty to maintain through the Website, your familiarity with these terms and conditions as they change over time. </p>

<p>
  <div>1. Information and Privacy</div>
  Company is strongly committed to protecting your privacy and has implemented a comprehensive <a href="privacy" target="_blank">privacy policy</a>. Company does not disclose or release personal or financial information about you except what is necessary to process your request for assistance. For details, please view our <a href="/privacy" target="_blank">Privacy Policy</a>.
</p>

<p>
  <div>2. Term</div>
  The Term begins when you sign up for LedgerView and continues as long as you use the Service. Clicking the button and entering your username means that you’ve officially “signed” the Terms. If you sign up for LedgerView on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms on their behalf.
</p>

<p>
  <div>3. Closing Your Account</div>
  You or the Company may terminate this Agreement at any time and for any reason by giving Notice to the other party. The Company may suspend our Service to you at any time, with or without cause. If the Company terminates your account without cause, it will refund a prorated portion of the service prepayment. The Company will not refund or reimburse you if there is cause, like a violation of these Terms. Once terminated, the Company may permanently delete your account and all the data associated with it from the Website.
  If you cancel the Service before the end of your current paid up term, your cancellation will take effect immediately and you will not be charged again. You will not be refunded for the prorated amount remaining on your current payment term.
</p>

<p>
  <div>4. Account and Password</div>
  You are responsible for keeping your account name and password confidential. You are also responsible for any account that you have access to, whether or not you authorized the use. You will immediately notify us of any unauthorized use of your accounts. The Company is not responsible for any losses due to stolen or hacked passwords and does not have access to your current password, and for security reasons, may only reset your password.
</p>

<p>
  <div>5. Payment Plans</div>
  The Company charges for monthly or annual plans (“plan”) and prices may be changed from time to time. Payments are due for any term on the same date, or the closest date in that term, to the day you signed up with and made your first payment (the "Pay Date").
</p>

<p>
  <div>6. Credit Cards</div>
  As long as you are a Member or have an outstanding balance, you will provide valid credit card information and authorize the Company to deduct the service charges against that credit card. You will replace the information for any credit card that expires with information for a different valid credit card. Anyone using a credit card represents and warrants that he or she is authorized to use that credit card, and that any and all charges may be billed to that credit card and will not be rejected. If the Company is unable to process the credit card order, we will try to contact you by email and suspend your account until payment can be processed.
</p>

<p>
  <div>7. Copyright/Restrictions on Use of Material</div>
  The Site(s), the software used with the Site, and all content such as computer code, text, graphics, site-images, etc., along with the collection, arrangement, and assembly of such, are the exclusive intellectual property of Company, or its affiliates, and is protected by both United States and international intellectual property laws; specifically, copyright, trademark/service mark, trade secret, trade dress, patent, and/or other proprietary rights and laws.
</p>

<p>
  Company grants to you only a limited, non-exclusive, non-transferable, non-assignable license to use the intellectual property on the Site solely for the purposes of viewing the Site. Except as expressly provided herein, you may not use, reproduce, modify, transmit, distribute, or publicly display or operate this Website without the prior written permission of Company. You may not use a part of this Website on any other website, without Company’s prior written consent. You may not sell, resell, de-compile, reverse engineer, disassemble, or otherwise translate any of the software portions of the Site or to a human-readable form. You may not copy, reproduce, republish, upload, post, transmit, or distribute content from the Site or in any way except as authorized herein or thereon or by other prior written permission from Company. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Site or, in whole or in part, nor use any content for any purpose other than that intended, unless previously authorized by us in writing. Any unauthorized use of any material contained in the Site is strictly prohibited.
</p>

<p>
  <div>8. No Unlawful Or Prohibited Use</div>
  As a condition of your use of the Website, you warrant to Company that you will not use the Website for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Website in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use of the Website. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
</p>

<p>
  <div>9. Trademarks, Service Marks, Logos, Corporate Identifications Protected</div>
  All trademarks, service marks, logos, or other corporate identifications (collectively "Marks") on this Website are trademarks or service marks of Company.  User agrees not to use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Marks in any way. The use of these Marks on or as a link to any other website is strictly prohibited without prior written approval by Company.
</p>

<p>
  <div>10. Links</div>
  This Website contains links to third party sites. User accesses these sites at User's own risk; the Website is not responsible for the contents, changes, updates, or other links contained in a linked site. Company provides these links merely as a convenience, and the inclusion of such links does not imply an endorsement by the Website or the URL of the site.
</p>

<p>
  <div>11. General Disclaimer</div>
  Although Company has made reasonable efforts to place accurate information on this Website, Company assumes no responsibility for the accuracy of the information. Company PROVIDES THIS WEBSITE CONTENT "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, TO THE FULLEST EXTENT ALLOWED BY LAW. FURTHER, ANY EDUCATIONAL MATERIAL, SEMINAR MATERIAL, OR ANY OTHER REFERENCE IS PROVIDED "AS IS" WHERE IS AND COMPANY FURTHER DISCLAIMS ALL OTHER WARRANTIES, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR THE CURRENT ACCURACY OF EDUCATIONAL OR OTHER MATERIAL AS BEING CURRENT INFORMATION OR INFORMATION THAT MAY BE RELIED ON TO MAKE BUSINESS OR OTHER DECISIONS. COMPANY DOES NOT WARRANT UNINTERRUPTED OR ERROR-FREE FUNCTIONS CONTAINED IN THIS SITE OR ITS CONTENT OR THAT THE WEBSITE  OR ITS SERVER ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
</p>

<p>
  <div>12. Limitation of Liability</div>
  To the maximum extent permitted by law, you assume full responsibility for any loss that results from your use of the Website and the Services, including any downloads from the Website. The Company will not be liable for any indirect, punitive, special, or consequential damages under any circumstances, even if they’re based on negligence or we’ve been advised of the possibility of those damages. Our total liability for all claims made about the Service in any month will be no more than what you paid Company for the Service the month before.
</p>

<p>
  <div>13. Indemnity</div>
  You agree to indemnify and hold the Company harmless from any losses (including attorney fees) that result from any claims you make that are not allowed under these Terms due to a "Limitation of Liability" or other provision. You also agree to indemnify and hold Company harmless from any losses (including attorney fees) that result from third-party claims that you or someone using your password did something that, if true, would violate any of these Terms.
</p>

<p>
  <div>14. No Partnership or Agency</div>
  User understands and acknowledges that no joint venture, partnership, employment, or agency relationship exists or is created between User and Company as a result of use of the Website.
</p>

<p>
  <div>15. Compliance With State And Federal Laws</div>
  Use of this Site is governed by all applicable federal, state, and local laws. All information available on this Site is subject to U.S. export control laws and may also be subject to the laws of the country where you reside. Company makes no representation that the Content on this Site is appropriate or available for use in other locations, and access to it from territories where the Content is illegal and prohibited.
</p>

<p>
  <div>16. Miscellaneous</div>
  Our failure to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision.
  You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Site or these Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred.
  These Terms of Use, including all terms, conditions, and policies that are incorporated into these terms by reference, constitute the entire integrated agreement between you and Company and govern your use of the Site, superseding any prior agreements that you may have with us.
  These Terms shall be construed in accordance with the laws of the State of Minnesota and the parties irrevocably consent to bring any action to enforce these Terms before an arbitration panel or before a court of competent jurisdiction in Minnesota if seeking interim or preliminary relief or enforcement of an arbitration award.
  If any part of these Terms is determined to be invalid or unenforceable pursuant to applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remainder of the Terms shall continue in effect.
</p>

<p>
  CONTACT US
  If you would like to request additional information regarding these Terms of Use, please contact us at support@ledgerview.app.
</p>

    </Article>
  )
}
