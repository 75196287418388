/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message, Image } from 'components/lib';
import Astronaut from './images/astronaut.jpg';

export function OnboardingView(props){

  const context = useContext(AuthContext);
  const views = [{

    name: 'Getting Started',
    description: `Welcome, ${context.user.name}!`,
    component: <Welcome/>

  }]

  if (context.permission.admin){
    views.push({
      name: 'Invite Your Team', 
      description: 'LedgerView is better when you invite your team.',
      component: <InviteUsers/>,
      
    });
  }

  views.push({
    name: 'Get Connected', 
    description: 'The first step to getting started is to get your accounting system connected.',
    component: <Connect/>,
    
  });


  if (context.user.duplicate_user){
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser/>

    })
  }

  return <Onboarding save onFinish='/dashboard' views={ views }/>

}

function DuplicateUser(){

  return (
    <Message
      type='warning'
      title={ `You already have an account` }
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />    
  )
}

function Welcome(){

  return (
    <Fragment>

      <p>LedgerView will help you get started organizing your books to keep them always backed up and up to date.</p>
        
      <p>Reach out to if you need help at <a href='mailto: support@ledgerview.app'>support@ledgerview.app</a></p>

      <Image source={ Astronaut }/> 

    </Fragment>
  )
}

function Connect(){

  return (
    <Fragment>

      <p>To make sure of LedgerView, the first step is to get your accounting system data connected.</p>
        
      
    </Fragment>
  )
}

function InviteUsers(props){

  return (
    <Form 
      data={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}