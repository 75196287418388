/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext } from 'react';
import { Form, Card, AccountNav, Animate, useAPI, Message, AuthContext } from 'components/lib';

export function Accountants(props){

  const context = useContext(AuthContext);
  

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          title='Settings'
           mediumWidth>

        
        <>
        <Message 
              title=''
              type=''
              text={'If you are part of an accounting firm or want to access all your clients, this option will enable you to access features to monitor all your clients.'}
             />

            <Form
              buttonText='Save'
              url='/api/account/campaign'
              method='PATCH'
              data={{
                campaign_id: {
                  type: 'hidden',
                  value: ''
                },
                active: {
                  type: 'switch',
                  label: 'Enable ',
                  default: context.user.account_settings?.firm
                }
                }
              }
              callback={ res => {
                context.user.account_settings.firm = !context.user.account_settings?.firm;
              }}
            />
          
          </>

        </Card>
      </Animate>
    </Fragment>
  );
}
