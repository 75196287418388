import React, {  useState, useContext, useEffect, useRef } from 'react';
import { AuthContext, Card, useAPI, ChatInput } from 'components/lib';
import { NavLink } from "react-router-dom";
import axios from 'axios';

import { MessageList } from "react-chat-elements"
import "react-chat-elements/dist/main.css"
import { FileUpload } from 'primereact/fileupload';
import Markdown from 'markdown-to-jsx'

export function Chat(props){

  const [analysisDetails, setAnalysisDetails] = useState({});
  const authContext = useContext(AuthContext);
  
  const [messages, setMessages] = useState([]);
  const [currMessage, setCurrMessage] = useState("");
  const [thread, setThread] = useState(undefined)

  // const [connections, setConnections] = useState([]);
  // const [realms, setRealms] = useState([]);
  // const list = useAPI('/api/app_connection');
  var analysis = useAPI('/api/appData/analysis/' + authContext.user.appConnection)

  useEffect(() => {
    if(analysis.data){
        // alert(JSON.stringify(analysis.data[0]))
        setAnalysisDetails(analysis.data[0])
    }

  }, [analysis]);

  const latestMessageIndex = messages.length - 1; // Index of the latest message

  const processChatInput = async (value) => {
    // alert(value);

    const newMsg = {
      position:"right",
      type:"text",
      title:"",
      text:value,
    };

    setMessages(current => [...current, newMsg]);

    // var postMsg = [];
    // messages.map((msg) => {
    //   postMsg.push({
    //     text: msg.text
    //   })
    // });
    // postMsg.push({text: value})

    console.log('appConn - ' + authContext.user.app_connection_id)
    var data = {
      headers: {
        responseType: 'stream'
      },
      app_connection_id: authContext.user.app_connection_id,
      message: value,
      thread: thread
    }
    console.log(JSON.stringify(data))
    // const res = await axios.post('/api/chatai', data);

    // const stream = response.data;

    const headers = {
      ...axios.defaults.headers.common,
      'Content-Type': 'application/json',
      responseType: 'stream'
    };
    setShouldAutoScroll(true);
    const res = await fetch('/api/chatai', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    });
    console.log(res.data)

    if (res.ok && res.body) {
      const reader = res.body.getReader();
      const decoder = new TextDecoder();
      let resMsg = ''; // Initialize the message string

      // Initialize an empty string for the current message
  let currMessage = "";

  function getLastValidJson(streamText) {
    const jsonPattern = /({[^}]*})/g;
    let lastValidJson = null;
    
    let match;
    while ((match = jsonPattern.exec(streamText)) !== null) {
        try {
            lastValidJson = JSON.parse(match[1]);
        } catch (error) {
            // Ignore invalid JSON and continue
        }
    }
    
    return lastValidJson;
}

  const processStream = async () => {
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        console.log('Stream complete');
        break;
      }

      // Convert the Uint8Array chunk to a string
      const decoder = new TextDecoder();
      const chunkText = decoder.decode(value);

      console.log(chunkText)
      const json = getLastValidJson(chunkText);
      if(json.thread !== undefined) {
        if(thread === undefined) {
          setThread(json.thread);
        }
      }
      else {
        // Append the chunk to the current message string
        currMessage = json.text;

        // Update the state to reflect the current message
        setCurrMessage(currMessage);
      }
    }

    // Once the stream is complete, save the full message to the list of messages
    const newResMsg = {
      position: "left",
      type: "text",
      title: "",
      text: currMessage,
    };

     setCurrMessage(undefined);
    setMessages(current => [...current, newResMsg]);
  };
    
      // Process the stream
      processStream();
    } else {
      console.error('Error:', res.status, res.statusText);
    }
  


    // if(thread === undefined) {
    //   // alert(res.data.thread)
    //   setThread(res.data.thread);
    // }

    // const resMsg = res.data.message;
    // const newResMsg = {
    //   position:"left",
    //   type:"text",
    //   title:"",
    //   text: resMsg,
    // };

    // setMessages(current => [...current, newResMsg]);

  }

  const uploadHandler = async ({ files }) => {
    const uploadURL = '/api/upload';
    const formData = new FormData();

    // Ensure files are appended correctly
    for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i], files[i].name);
    }

    try {
        const response = await axios.post(uploadURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log('Files uploaded successfully:', response.data);
        // Handle success
    } catch (error) {
        console.error('Error uploading files:', error);
        // Handle error
    }
};

const divRef = useRef(null);
const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

useEffect(() => {
  const handleScroll = () => {
    if (divRef.current) {
      const isAtBottom =
        divRef.current.scrollHeight - divRef.current.scrollTop <= divRef.current.clientHeight;
        // console.log("is at bottom " + isAtBottom + " - " + divRef.current.scrollHeight + " - " + divRef.current.scrollTop + ' - ' + divRef.current.clientHeight)
      setShouldAutoScroll(isAtBottom);
    }
  };

  const div = divRef.current;
  if (div) {
    div.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (div) {
      div.removeEventListener('scroll', handleScroll);
    }
  };
}, [divRef]);

  useEffect(() => {
    if(shouldAutoScroll) {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    }
  }, [currMessage]);


  // useEffect(() => {
  //   if (divRef.current) {
  //     divRef.current.scrollTop = divRef.current.scrollHeight;
  //   }
  // }, [currMessage]);

  return (
    <>
      <nav className='subnav'>
      <NavLink exact to='/ai' activeClassName='active'>Chat</NavLink>
      <NavLink exact to='/ai/agents' activeClassName='active'>Agents</NavLink>
      </nav>

        <div style={{ marginBottom: '0px'}}>
        <Card minHeight='388px'>
            <div style={{paddingBottom: 20}}>
                <div style={{marginTop: '16px', paddingRight: '10px', fontSize: '20px'}}>Use the LedgerView AI Assistant to answer questions about your business</div>
                {/* <div style={{paddingBottom: '12px'}}><b>Date Analyzed: {analysisDetails.date}</b></div> */}

                {/* <div>
                    <div style={{paddingBottom: '12px'}}>
                        <div><b>Summary:</b></div>
                        <div>{analysisDetails.details?.summary}</div>
                    </div>

                    <div style={{paddingBottom: '12px'}}>
                        <div><b>Potential Risks:</b></div>
                        {analysisDetails.details?.risks?.map((item, index) => {
                            return(<div> {index+1}. {item.risk.text}</div>)
                        })}
                    </div>

                    <div style={{paddingBottom: '12px'}}>
                        <div><b>Positives:</b></div>
                        {analysisDetails.details?.positives?.map((item, index) => {
                            return(<div> {index+1}. {item.positive.text}</div>)
                        })}
                    </div>
                </div> */}
            </div>
        
            {/* <MessageList
              className='message-list'
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={messages}
            /> */}

<div style={{ 
      height: '450px', 
      display: 'flex',
      width: '100%'
    }}>

<div 
ref={divRef} 
style={{ 
  height: '85%', 
  width: '100%',
  overflowY: 'auto', 
}}>
            {
              messages.map((msg) => {
                return(
                  <div style={{paddingBottom: '24px'}}>
                    <div style={{fontWeight: 'bold'}}>{msg.position === 'right' ? 'You' : 'LedgerView AI'}</div>
                    <Markdown>{msg.text}</Markdown>
                  </div>
                )
              })
            }

            {
              currMessage && 
                  <div style={{paddingBottom: '24px'}}>
                    <div style={{fontWeight: 'bold'}}>{'LedgerView AI'}</div>
                    <Markdown>{currMessage}</Markdown>
                  </div>
                
            }
</div>
</div>
<div style={{ position: 'absolute', bottom: 20, left: 20, right: 20}}>
  {/* Your content here */}

            <ChatInput callback={processChatInput} />
            {/* <FileUpload 
              name="demo[]" 
              customUpload={true}
              uploadHandler={uploadHandler}
              multiple={true}
              accept="image/*,application/pdf,text/plain" 
              maxFileSize={100000000} 
              emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} 
            /> */}

</div>
{/* <Input
  referance={inputReferance}
  placeholder="Type here..."
  multiline={true}
  rightButtons={<Button text='Send' 
  onClick={(msg) => alert('here - ' + inputReferance)} />}
/> */}
        {/* <TextField fullWidth multiline 
          inputProps={{
            sx: {
              fontSize: '16px',
              lineStepHeight: '200px'
            }
          }}
          InputLabelProps={{
            sx: {
              fontSize: '20px'
            },
          }} 
        label="" id="fullWidth"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            alert(e.target.value);
            e.preventDefault();
          }
        }}
        />

        <Button variant='outlined' size="medium" label='Go' onClick={(e) => {
            alert('hello');
        }}>Go</Button> */}
        {/* endIcon={<SendIcon />} */}
        </Card>
        </div>
      
      

<div>

</div>

    
    </>
  );
}



