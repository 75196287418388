import React, { useEffect } from 'react';
import { Button } from 'components/lib';
import Style from './app_connection_details.module.scss';
import axios from 'axios';

export function AppConnectionDetails(props){

  

  useEffect(() => {

  }, []);

  

  const disconnect = () => {
    axios.patch('/api/app_connection/disconnect/' + props.item.id);
  }

  return (

    
    <div className={ Style.AppConnectionDetails }>
      {/* <Icon color='dark' image='mail' className={ Style.icon }/> */}
      {props.item.name}
      <div style={{paddingTop: 30}}>
        <Button icon='trash-2' action={() => disconnect()} />
      </div>
   </div>
  );
}
