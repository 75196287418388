import React, { useEffect } from 'react';
import { Card } from 'components/lib';


export function Error(props){
 
  useEffect(() => {
    

  }, []);
  

  return (
    <>
    
    <Card >
        <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2>Before connecting to QuickBooks Online, you must verify your email address with QuickBooks. Please do so and then try again.</h2>
    </div>
    
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      
    </div>
  </Card>
    
    
    </>
  );
}
