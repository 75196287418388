import React from 'react';
import {useParams} from 'react-router-dom';
import { NavLink } from "react-router-dom";
import RecordDetails from "../RecordDetails";

export function Details(props){

  
  const {id} = useParams();

  return (
    <>
    <nav className='subnav'>

    <NavLink exact to='/app/cleanup/index' activeClassName='active'>Clean-Up</NavLink>
    {/* <NavLink exact to='/app/cleanup/rules' activeClassName='active'>Alerts</NavLink> */}
    <NavLink  to='/app/cleanup/details' activeClassName='active'>Details</NavLink>
    </nav>

        {/* <Breadcrumbs items={[
          { name: 'Activity', url: '/app/activity/index' },
          { name: 'Record Details', url: '#' }
        ]}/> */}
        <RecordDetails id={id} />
       

    </>
  );
}



