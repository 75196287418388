/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import { Row, Link, Hero, Features, Grid, Icon, Image } from 'components/lib';
import ImgDashboard from './images/dashboard.png';
import { TabView, TabPanel } from 'primereact/tabview';
import Style from './features.module.scss';

export function Home(props){

  return(
    <Fragment>

      <Hero
        title='Real-time monitoring of your business data so that you can...'
        tagline='Keep it up to date and clean, gain valuable insights, and utilize it in ways you never thought possible.'
        image={ ImgDashboard }
        showSignup={false}
        />
  
        <Row title='Easily Monitor & Fix Transactions & Data' desc="Clean-up uncategorized and mis-categorized transactions and monitor them for completeness, correctness, and fraud." color='white'>
        
        <div className={ Style.features }>
        <Grid cols={ 3 }>
  
          <div>
          <h3>Never Fear Data Clean-Up</h3>
          <p>Detect and be alerted to transactions and data that is messy or needs attention before it gets lost in the past. </p>
          </div>
  
          <div>
          <h3>Find Issues Immediately</h3>
          <p>LedgerView will keep a to-do list of all the data that needs attention and help you clean it up.</p>
          </div>
  
          <div>
          <h3>On Demand Back-Up & Restore</h3>
          <p>Your SaaS data might be backed-up for disaster, but data mistakes are never easy to handle. LedgerView shows an audit trail and enables rollback of transactions to any point in time.</p>
          </div>
         
          <div>
            <h3>Applications Supported</h3>
            <div>
              Connect your accounting, payments, and expense tracking apps<br/><br/>
              QuickBooks <br/>
              Xero <br/>
              Stripe<br />
              <i>Coming Soon!</i><br />
              Dext <br />
              BILL<br />
              Ramp<br />
              ADP 
            </div>
          </div>

          <div>
            <h3>Built-in Monitors</h3>
            <div>
              Over a dozen standard data monitors, plus the ability to create your own.<br /><br/>
              Uncategorized Expenses <br/>
              Missing Classes <br/>
              Large Checks<br />
              Duplicate Transactions <br />
              Potential Fraud Payments <br />
              Vendor Issues <br />
              Customer Issues <br />
              Invoice Product Issues <br />
            </div>
          </div>

          <div>
            <h3>Automatic Notifications</h3>
            <div>
              Be notified when issues are detected or alerts are triggered.<br /><br/>
              By e-mail <br/>
              MS Teams <br/>
              Slack<br />
              Asana<br />
              Karbon<br />
              Hubspot (coming soon)<br />
            </div>
          </div>
  
  
        </Grid>
        </div>
{/*         
        <TabView panelContainerStyle={{border: '2px solid lightgray', height: '300px'}}>
            <TabPanel header="Data Applications Supported" leftIcon="pi pi-calendar mr-2">
                <p className="m-0">
                  QuickBooks <br/>
                  Xero <br/>
                  Dext (coming soon)
                </p>
            </TabPanel>
            <TabPanel header="Notifications" leftIcon="pi pi-user ml-2">
                <p className="m-0">
                  When a monitor detects an issue, LedgerView automatically notifies you or your bookkeeper. It can send alerts to:</p>
                  <p>
                  Microsoft Teams <br/>
                  Slack<br/>
                  Asana<br/>
                  Karbon<br />
                  Hubspot (coming soon)
                  </p>
                  <p>Plus, you get a weekly summary of open issues automatically sent to you.
                </p>
            </TabPanel>
            <TabPanel header="Built in Monitoring Rules" leftIcon="pi pi-search mr-2">
                <p className="m-0">
                  Uncategorized Expense <br/>
                  Missing Classes <br/>
                  Large Checks
                </p>
            </TabPanel>
          </TabView> */}
        
        </Row>
  
        <Row title='How it Works' desc="" color='brand'>
  
        <div className={ Style.features }>
        <Grid cols={ 3 }>
  
          <div>
          <h3 style={{color: 'white'}}>1. Connect Your Data</h3>
          <p>Once connected, LedgerView will see and detect new transactions and data that you create in your business. </p>
          </div>
  
          <div>
          <h3 style={{color: 'white'}}>2. Process & Store</h3>
          <p>Your data gets processed and stored in our data warehouse where issues can be seen and corrected, you can roll back changes when needed.</p>
          </div>
  
          <div>
          <h3 style={{color: 'white'}}>3. Be Notified & Use</h3>
          <p>You'll be notified in real-time and given help to fix any issues. Slack and MS Teams can be connected, or Asana and Karbon for our accounting friends.</p>
          </div>
         
          
  
  
        </Grid>
        </div>


        </Row>

      <Row title='Why LedgerView' color='white'>
        <Features />
      </Row>
{/* 
      <Row title='What Does the Future Hold' desc="We have lots and lots on our roadmap. LedgerView is ultimately a data platform that businesses can utilize this in many ways to run their business better." color='brand'>
  
        <div className={ Style.features }>
        <Grid cols={ 3 }>
  
          <div>
          <h3 style={{color: 'white'}}>AI Assistant</h3>
          <p>Since your transactions are the life-blood of your business, we're putting them to work with an AI Assistant that knows about your data and can help summarize, interpret, or offer suggestions on how to run your business better. Think of it as Gen AI, your really smart chat assistant, that knows about your business. </p>
          </div>
  
          <div>
          <h3 style={{color: 'white'}}>Dashboards</h3>
          <p>COMING SOON: Financial people love their dashboards, and we're working on ways for you to create pivot tables and drag and drop charts to see exactly what you want to see. </p>
          </div>
  
          <div>
          <h3 style={{color: 'white'}}>Customized Apps</h3>
          <p>COMING SOON: Every business has unique ways of doing things. LedgerView wants to help build the forms, approvals, and workflows that are unique to you. You'll be able to build your own apps to help you run your business.</p>
          </div>
         
          
  
  
        </Grid>
        </div>
        </Row> */}

        <Row title='Get Started Today' align='center' color="brand" >
        <Link btn url='/signup' text='Sign Up Now'/>
      </Row>
    </Fragment>
  );
}
