import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Card, useAPI, Table, AuthContext, ViewContext, Button, Grid } from 'components/lib';

export function OrganizationList(props){

  const records = useAPI('/api/user/associatedDataConnections');
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  
  const [recordList, setRecordList] = useState([]);
  
  const updateRecordList = (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      records.map((item) => {
        
        recList.push({
          id: item.id,
          org_Name: item.org_Name,
          name: item.name,
          health_Score: item.health_Score,
          records_to_Clean: item.records_to_Clean,
          plan: item.plan,
          active:item.active,
          checked: false
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);

  const loadAccount = (rec, index) => {
    authContext.switchAccount(rec.id);
    //History.push('/dashboard');
  };

  function createNewAccount(data, callback){
  }

  function importClients(data, callback){
    viewContext.modal.show({
      title: 'Import Clients from Karbon Practice Management',
      text: 'Enter your Karbon API Access Key below and your active organization clients will be automatically connected to LedgerView.',
      form: {
        access_key: {
            label: 'Access Key',
            type: 'text',
            required: 'true',
            default: '',
          },
      },
      buttonText: 'Import',
      url: '/api/auditRule/',
      method: 'POST'

    }, (res) => {

      viewContext.notification.show('Client import has started', 'success', true);
      callback(res);

    });
  }

  function detail() {
    return(
     
      <Fragment>
  
        <Card>
        <>
          <Grid cols='2'>
            <Card
              title='Clients'
              // loading={accountList?.data}
              minHeight='120px'
            >
            </Card>
  
            <Card
              title='Attention Required'
              subtitle='last 30 days'
              // loading={accountList?.data}
              minHeight='120px'
            >
              
            </Card>
  
          </Grid>
        </>
        </Card>
      </Fragment>
     
          );
  }

  const selectRecord = (id) => {
    var newRecList = []
    recordList.map((rec) => {
      if(rec.id === id) {
        rec.checked = !rec.checked;
      }
      else {
        rec.checked = false
      }
      newRecList.push(rec)
    })
    setRecordList(newRecList)
  }

  function editAccount(data, callback){

    viewContext.modal.show({
      title: 'Update Account: \'' + data.name + '\'',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        is_active: {
            label: 'Is Active',
            type: 'switch',
            default: data.active,
          },
      },
      buttonText: 'Update',
      url: '/api/auditRule/' + data.id,
      method: 'PATCH'

    }, (res) => {

      viewContext.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  return(
    <Fragment>

      <Card>
      <>
        
        <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 0, textAlign: 'right', paddingBottom: 20}}>
          <Button style={{marginRight: 20}} text='Create New Client' small='false' action={(createNewAccount)} />
          <div style={{paddingLeft: '10px', marginTop: '1px'}}>
            <Button text='Import Clients...' small='false' action={(importClients)} />
          </div>
        </div>
      <Table
          data={ recordList }
          // loading={ accountList?.loading }
          search={true}
          sort={true}
          show={['org_Name', 'name','health_Score','records_to_Clean','connections', 'plan','active']}
          hyperlink={{
            col: 'name',
            url: '#',
            //callback: loadAccount
          }}
          actions={{ 
            hyperlink: loadAccount,
            edit: editAccount,
            // view: loadAccount,
            // selectRecord: selectRecord, 
          }}
          // detail={detail}
        >
        </Table>
        </>
      </Card>

    </Fragment>

  );
}
