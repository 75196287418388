import React, { useState, useEffect } from 'react';
import { Card, useAPI } from 'components/lib';
import { Table } from 'components/lib';
import { NavLink } from "react-router-dom";


export function RestoreHistory(props){
  
  const [recordList, setRecordList] = useState([]);
  
  var records = useAPI('/api/appData/restoreHistory')
  
  const updateRecordList = (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      records.map((item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          Restored_At: item.created_at,
          status: item.status
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);
  

  const setActiveRecord = (rec, index) => {
    window.location = '/app/activity/restoreDetails/'+rec.id;
  };
  

  return (
    <>
      <nav className='subnav'>

      <NavLink exact to='/app/activity/restore' activeClassName='active'>Data Restore</NavLink>
      <NavLink exact to='/app/activity/restoreHistory' activeClassName='active'>Restore History</NavLink>
      </nav>
   
      <>

      <Card>
      <Table
        search
        data={ recordList }
        //loading={ table.loading }
        hide={'id'}
        hyperlink={{
          col: 'Restored_At',
          //url: '/app/DataHistory',
          //callback: loadCompany
        }}
        badge={{ col: 'status', color: 'blue', condition: [

          { value: 'in progress', color: 'yellow' },
          { value: 'error', color: 'red' }
        
        ]}}
        actions={{ 
          hyperlink: setActiveRecord,
          edit: setActiveRecord, 
        }}
      >
      </Table>
      </Card>
      </>
    
    </>
  );
}
