

import { Animate, Row } from 'components/lib';
import Markdown from 'markdown-to-jsx';

export function Cleanup(props){



  return (
    <Animate type='pop'>
      <Row color='tint' align='center' title='Clean-Up Your Accounting'>
        <Markdown>
        Keeping QuickBooks or Xero up to date and clean is a real hassle. If you're like most business owners, you know what I mean. 

        </Markdown>
      </Row>

    </Animate>
  )
}
