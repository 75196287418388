/***
*
*   SUB NAV
*   Sub navigation element (located underneath the header).
*
**********/

import React from 'react';
import { NavLink } from 'react-router-dom';
import './sub.scss';

export function SubNav(props){

  return(
    <nav className='subnav'>
      { props.items?.items.map(item => {

        return (
          <NavLink
            key={ item.label }
            to={ item.url }
            activeClassName='active'>

            { item.label }

          </NavLink>
        );
      })}
    </nav>
  );
}
