import { Chat } from 'views/app/activity/chat';
import { Help } from 'views/dashboard/help';
import { App_connection } from 'views/app/app_connection';


const Routes = [
  {
    path: '/help',
    view: Help,
    layout: 'analyzer',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/ai',
    view: Chat,
    layout: 'analyzer',
    permission: 'user',
    title: 'AI Assistant'
  },
  {
    path: '/analyzer/app_connection',
    view: App_connection,
    layout: 'analyzer',
    permission: 'user',
    title: 'Connections'
  }
]

export default Routes;
