/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon } from 'components/lib';
import Style from './features.module.scss';

export function Features(props){

  return(
    <div className={ Style.features }>
      <Grid cols={ 3 }>

        <div>
        <Icon image='credit-card' size={ 16 } />
        <h3>Trust Your Books</h3>
        <p>LedgerView will oversee your accounting and track records that need to be cleaned up. It saves hours and keeps your books up to date, and backed-up in real-time. </p>
        </div>

        <div>
        <Icon image='maximize-2' size={ 16 } />
        <h3>Expand Communication</h3>
        <p>While your data is being watched in real-time, LedgerView creates a notification thread in MS Teams or Slack to help your team discuss and communicate about issues.</p>
        </div>

        <div>
        <Icon image='activity' size={ 16 } />
        {/* <h3>Backup & Restore</h3>
        <p>Your SaaS data might be backed-up for disaster, but data mistakes are never easy to track or rollback. LedgerView shows an audit trail and restore any or all transactions to a point in time.</p> */}
        <h3>Monitor Changes</h3>
        <p>Your SaaS data might be backed-up for disaster, but data mistakes are never easy to track. LedgerView creates an audit trail of all changes.</p>
        </div>

        <div>
        <Icon image='droplet' size={ 16 } />
        <h3>Faster Month End & Tax Prep</h3>
        <p>With the books kept up to date and issues resolved, this eliminates the review and chasing down of answers to close the month or prepare the tax return. </p>
        </div>

        <div>
        <Icon image='lock' size={ 16 } />
        <h3>Ensure Fraud Never Happens</h3>
        <p>The rules-based engine alerts you if it sees transactions that are out of the ordinary or a potential risk for fraud.</p>
        </div>

        <div>
        <Icon image='database' size={ 16 } />
        <h3>Better Client Onboarding</h3>
        <p>Streamline client proposals and onboarding by running a health check against your prospect’s data. This tells exactly how messy the client data is, what needs to be addressed, and how to explain the cost of the engagement.</p>
        </div>

        <div>
        <Icon image='briefcase' size={ 16 } />
        <h3>Accounting Firms</h3>
        <p>LedgerView connects directly with your Practice Management application so that tasks and client work are automatically created and easily tracked. </p>
        </div>

        <div>
        <Icon image='users' size={ 16 } />
        <h3>Client Accounting</h3>
        <p>Empower users to work with their clients closely and manage only their assigned clients.</p>
        </div>


      </Grid>
    </div>
  );
}
