/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment } from 'react';
import { Form, Card, AccountNav, Animate, useAPI, Message } from 'components/lib';

export function Emails(props){

  
  // fetch
  const campaign = useAPI('/api/account/campaign');
  
  const days_of_week = [
    {
      label: "Sunday",
      value: 0
    },
    {
      label: "Monday",
      value: 1
    },
    {
      label: "Tuesday",
      value: 2
    },
    {
      label: "Wednesday",
      value: 3
    },
    {
      label: "Thursday",
      value: 4
    },
    {
      label: "Friday",
      value: 5
    },
    {
      label: "Saturday",
      value: 6
    },
  ]
  

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          title='E-mail Settings'
          loading={ campaign.loading } mediumWidth>

        { campaign?.data &&
        <>
            <Message 
              title='E-mail Summary'
              type='success'
              text={'An e-mail summary will be sent each week showing account activity and what records should be cleaned up.'}
             />

            <Form
              buttonText='Save'
              url='/api/account/campaign'
              method='PATCH'
              data={{
                campaign_id: {
                  type: 'hidden',
                  value: campaign.data?.id
                },
                active: {
                  type: 'switch',
                  label: 'Send E-mail Summary',
                  default: campaign.data?.active
                },
                email_addrs: {
                  label: 'E-mail Addresses',
                  type: 'chip',
                  required: false,
                  value: campaign.data.email_addrs,
                  errorMessage: '',
                },
                day_of_week: {
                  label: 'Day of Week',
                  type: 'select',
                  default: campaign.data?.day_of_week,
                  options: days_of_week.map(x => { return {
                    value: x.value, label: x.label

                  }})
                },
                }
              }
              callback={ res => {
              }}
            />
          
          </>
}
        </Card>
      </Animate>
    </Fragment>
  );
}
