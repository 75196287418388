/***
*
*   TABLE
*   Please view the full documentation: https://docs.usegravity.app/ui/table
*
*   PROPS
*   data: array of objects for body and header (optional)
*   search: bool to show the search field
*   sort: allow the table columns to be sorted
*   loading: bool to toggle loading spinner
*   badge - object containing column name and color to add badges to column
*   show - array of columns (object key names) to show (shows all if not provided)
*   hide - array of columns (object key names) to hide
*   actions: object with edit/delete keys set to callback functions (optional)
*
**********/

import React, { Fragment, useState, useEffect } from 'react';
import { Loader, Search, CheckList } from 'components/lib';
import { Header } from './header';
import { Body } from './body';
import Style from './table.module.scss';

export function Table(props){

  // state
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [filter, setFilter] = useState(false);
  const [recChecklist, setRecChecklist] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (props.data){

      // create the headers
      let header = props.data.header || [];

      if (!header.length){
        for (let key in props.data[0]){
          header.push({

            name: key,
            title: key.replace('_', ' ').replace('_', ' '),
            sort: key === 'actions' ? false : true

          });
        }
      }

      setBody(props.data);
      setHeader(header);
      
      if(props.checkItems) {
        setupChecklist();
      }
      
    }

    // alert(props.data.length)
  }, [props.data]);

  function setupChecklist(){
    
    const rows = filter.length ? [...filter] : [...props.data];

    let recTypeListLoc = {};
    rows.forEach(item => {
      if(recTypeListLoc.hasOwnProperty(item[props.checkItems])) {
        recTypeListLoc[item[props.checkItems]] = recTypeListLoc[item[props.checkItems]] + 1;
      }
      else {
        recTypeListLoc[item[props.checkItems]] = 1;
      }
    });

    let recTypeListStr = [];
    
    for (const recType of Object.keys(recTypeListLoc)) {
      recTypeListStr.push({
        name: recType + " (" + recTypeListLoc[recType] + ")",
        checked: true,
        // onClick: () => checkItemClicked(recType) 
      });
    }

    recTypeListStr.sort((a, b) => {
      // Convert both names to lowercase for case-insensitive sorting
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
  
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
  });

  recTypeListStr.unshift({
    name: "All",
    checked: true,
    // onClick: () => checkItemClicked("All") 
  });

    setRecChecklist(recTypeListStr)
}

function checkItemClicked(listIndex) {
  // alert(JSON.stringify(checkItem))
  
    const allItem = recChecklist[0];
    var bHasAll = false;
    var bToggle = false;
    if(allItem.name === "All") {
      bHasAll = true;
      bToggle = ! allItem.checked;
    }

    var bAllChecked = true;
    const newList = recChecklist.map((item, index) => {
      

      if((listIndex === 0) && (bHasAll === true)) {
        item.checked = bToggle;
      } else if(index === listIndex) {
        item.checked = !item.checked;
      }

      if((bHasAll === true) && (index !== 0) && (item.checked === false)) {
        bAllChecked = false;
      }

      return item;
    })

    if(bHasAll === true) {
      newList[0].checked = bAllChecked;
    }

  setRecChecklist(newList);
    search(searchTerm);

    // const rowsToShow = recChecklist.map((item, index) => {
    // body.forEach(row => {
    //     try{
    //     if (row[props.checkItems].toString().toLowerCase() == item.name.toLowerCase()){

    //       if (!rowsToShow.includes(row))
    //         return row;

    //     }
    //   }
    //   catch{
        
    //   }
    // })
    // })

    // setFilter(rowsToShow);

}

  // loading
  if (props.loading){
    return (
      <div className={ Style.loading }>
        <Loader />
      </div>
    );
  }

  // no data
  if (!header && !body)
    return false

  function sort(column, direction){

    const rows = filter.length ? [...filter] : [...body];

    rows.sort(function(a,b){

      if (a[column] && b[column]){

        a[column].badge ?
          a = a[column].label : a = a[column];

        b[column].badge ?
          b = b[column].label : b = b[column];

        if (direction === 'desc'){

          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;

        }
        else {

          if (a < b) return -1;
          if (a > b) return 1;
          else return 0;

        }
      }
      else {

        return false;

      }
    });

    filter ? setFilter(rows) : setBody(rows);

  }

  function search(term){

    setSearchTerm(term);
    // search each cell in each row &
    // update state to show only filtered rows
    let rowsToShow = [];

    body.forEach(row => {
      for (let cell in row){
        try{
        if (row[cell].toString().toLowerCase().includes(term.toLowerCase())){

            if (!rowsToShow.includes(row))
              rowsToShow.push(row);


        }
      }
      catch{
        
      }
      }
    })

    setFilter(rowsToShow);
    if(props.checkItems) {
      // setupChecklist();

      var rowsToShowChecked = [];
      rowsToShow.forEach(row => {
        const checkItem = recChecklist.map((checkItemFromList) =>{
          if(checkItemFromList.name.substring(0, checkItemFromList.name.indexOf(' ')).toLowerCase() === row[props.checkItems].toString().toLowerCase()) {
            // console.log(row[props.checkItems].toString().toLowerCase() + " - " + checkItemFromList.name.substring(0, checkItemFromList.name.indexOf(' ')).toLowerCase())
            
            if(checkItemFromList.checked === true) {
              rowsToShowChecked.push(row);
            }
            // return checkItemFromList;
          }
        });

        // if(checkItem) {
        //   // console.log(JSON.stringify(checkItem))
        //   if(checkItem.checked == true) {
        //     rowsToShowChecked.push(row);
        //   }
        // }
      });
      setFilter(rowsToShowChecked);
    }
  }

  function editRowCallback(res, row){

    let state = [...body];
    let stateRow = state[state.findIndex(x => x.id === row.id)];
    Object.keys(res).map(key => stateRow[key] = res[key].value);
    setBody(state);

  }

  function deleteRowCallback(res, row){
   
    let state = [...body];
    state.splice(state.findIndex(x => x.id === row.id), 1);
    setBody(state);

  }

  function hyperlinkRowCallback(res, row){
   
    let state = [...body];
    state.splice(state.findIndex(x => x.id === row.id), 1);
    setBody(state);

  }

  return (
    <Fragment>
      <div style={{display: 'flex', paddingLeft: '20px', paddingRight: '20px'}}>
      
      {props.checkItems &&
      <div style={{minWidth: '20%', paddingTop: '14px', marginRight: '0px'}}>
        <div style={{marginBottom: '24px', paddingRight: '10px', fontSize: '20px'}}>Record Types  </div>
          
        <CheckList
          interactive
          circles
          hideCross
          items={recChecklist}
          onItemClick={checkItemClicked}
        />
      </div>
      }

      <div style={{width: '100%'}}>
        { props.search &&
          <Search className={ Style.search } callback={ search } throttle={ props.throttle }/> }

        <table className={ !props.naked && Style.table }>

          { header &&
            <Header
              showRowNumbers={props.showRowNumbers ? props.showRowNumbers : false}
              data={ header }
              callback={ sort }
              show={ props.show }
              hide={ props.hide }
              actions={ props.actions }
            />
          }
          { body &&
          <>
            <Body
              showRowNumbers={props.showRowNumbers ? props.showRowNumbers : false}
              data={ filter ? filter : body }
              show={ props.show }
              hide={ props.hide }
              badge={ props.badge }
              hyperlink={ props.hyperlink }
              detail={props.detail}
              actions={{

                edit: props.actions?.edit,
                view: props.actions?.view,
                delete: props.actions?.delete,
                email: props.actions?.email,
                custom: props.actions?.custom,
                hyperlink: props.actions?.hyperlink,
                selectRecord: props.actions?.selectRecord

              }}
              callback={{

                edit: editRowCallback,
                delete: deleteRowCallback,
                hyperlink: hyperlinkRowCallback,

              }}
            />
            
            </>
          }
          
        </table>
      </div>
      {/* <div style={{paddingTop: 20}}>
        {props.data.length > pageSize &&
      <Pagination onChange={handlePageChange} shape='rounded' size="large" showFirstButton showLastButton count={Math.ceil(props.data.length / pageSize)} />
}
      </div> */}

      </div>
    </Fragment>
  );
}
