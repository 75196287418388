import { Home } from 'views/website/home';
import { Contact } from 'views/website/contact';
import { Support } from 'views/website/support';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';
import { Cleanup } from 'views/website/cleanup';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'LedgerView - '
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact'
  },
  {
    path: '/support',
    view: Support,
    layout: 'home',
    title: 'Support'
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing'
  },
  {
    path: '/cleanup',
    view: Cleanup,
    layout: 'home',
    title: 'Data Clean-Up'
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions'
  },
]

export default Routes;