import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AuthContext, Card, useAPI, Button, Form } from 'components/lib';
import { NavLink } from "react-router-dom";
import { Table, ViewContext } from 'components/lib';
import Select from 'react-select';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Style from './activity.module.scss';

export function Activity(props){

  const [recordList, setRecordList] = useState([]);
  const [dataTypeList, setDataTypeList] = useState([]);
  const [currentDataType, setCurrentDataType] = useState(null);

  const authContext = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [numSelected, setNumSelected] = useState(0);
  const [selectedRecordList, setSelectedRecordList] = useState([]);

  const [currentRealm, setCurrentRealm] = useState(authContext.user.appConnection);

  const [showRestore, setShowRestore] = useState(false);
  const context = useContext(ViewContext);
    
  // const [connections, setConnections] = useState([]);
  // const [realms, setRealms] = useState([]);
  // const list = useAPI('/api/app_connection');
  var records = useAPI('/api/appData/recordsProcessed/' + authContext.user.appConnection)
  var dataTypes = useAPI('/api/appData/dataTypes/')

  // useEffect(() => {
  //   if(list?.data?.length) {
  //     //console.log('list.data.length - ' + list.data.length)
  //     setConnections(list.data);
  //   }
  //   var listRealms = [];
  //   list?.data?.map((item) => {
  //     listRealms.push({value: item.data_src_id, label: item.name})
  //   });
  //   setRealms(listRealms);

  // }, [list.data]);

  const runBackup = () => {
    axios({
      url: '/api/appData/runBackup/' + authContext.user.appConnection,
      method: 'get',
    })

    context.notification.show('Your data is being updated now.', 'success', true, 'toast', 'heart');

  }

  const updateRecordList = (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      records.map((item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          checked: false,
          type: item.record_type,
          name: item.name,
          Last_Updated: item.last_updated_at,
          url: item.url
          //recDetails: data.recDetails
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);

  useEffect(() => {
    if(dataTypes?.data?.length) {
      
      let recList = [];
      recList.push({
        value: 'null',
        label: 'All Data Types'
      });

      dataTypes.data.map((item) => {
       
        recList.push({
          value: item.name,
          label: item.name
        });
      });

      setDataTypeList(recList);

    }

  }, [dataTypes.data]);

  const selectDataType = (val) => {
    setCurrentDataType(val)
    
  }

  //const realmId = '4620816365064851030';

  // const onDataChange = (items) => {
  //   let recList = [];

  //   items.docs.forEach((item) => {
  //     let id = item.id;
  //     let data = item.data();
  //     recList.push({
  //       id: id,
  //       name: data.name,
  //       type: data.operation,
  //       lastUpdated: data.lastUpdated,
  //       //recDetails: data.recDetails
  //     });
  //   });

  //   setRecordList(recList);
  // };

  useEffect(() => {
    
    if(currentRealm) {
      //alert(currentRealm)
    // const unsubscribe = DataHistoryService.getAll(currentRealm).orderBy("lastUpdated", "desc").onSnapshot(onDataChange);
    
    // return () => unsubscribe();

    
    }
  }, [currentRealm]);


  const selectRecord = (id) => {

    var numSelected = 0;
    recordList.map((rec) => {
      if(rec.id === id) {
        rec.checked = !rec.checked;
      }
      if(rec.checked === true) {
        numSelected = numSelected + 1;
      }
    })
    setSelectedRecordList(oldArray => [recordList] );
    setNumSelected(numSelected);
  }

  const setActiveRecord = (rec, index) => {
    window.location = 'details/'+rec.id;
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const findRecs = async () => {
    const res = await axios({

      url: '/api/appData/recordsProcessedSearch/' + authContext.user.appConnection + '/' + moment(selectedDate).format('YYYY-MM-DD hh:mm:A') + '/' + currentDataType,
      method: 'get',
      
    })
    
    records = res.data?.data;
    updateRecordList(records)
  };

  var allDataLabel = 'Restore all data to: ' + selectedDate.toLocaleString();
  if(currentDataType) {
    allDataLabel = 'Restore all ' + currentDataType + 's to: ' + selectedDate.toLocaleString();
  }
  const selectedRecLabel =  numSelected + ' selected records';

  return (
    <>
    <nav className='subnav'>
    <NavLink exact to='/app/activity/index' activeClassName='active'>Activity</NavLink>
    <NavLink exact to='/app/cleanup/index' activeClassName='active'>Clean-Up</NavLink>
    </nav>
    { showRestore &&
        <Card title='Restore Data' shadow className={ Style.feedback }>

          <Button 
            icon='x' 
            color='white' 
            className={ Style.close } 
            size={ 16 }
            action={ e => setShowRestore(false) }
          />

          <Fragment>
              <Form 
                data={{
                //   rating: {
                //   type: 'hidden',
                //   value: ratings[rating]
                // },
                  allData: {
                  label: 'Select the data you would like to restore',
                  type: 'checkbox',
                  cols: 1,
                  options: [selectedRecLabel, allDataLabel],
                  required: true
                },
                dtSelected: {
                  type: 'hidden',
                  value: {selectedDate}
                },
                typeSelected: {
                  type: 'hidden',
                  value: {currentDataType}
                },
                recsSelected: {
                  type: 'hidden',
                  value: {selectedRecordList}
                }
                }}
                method='POST'
                url='/api/appData/restore'
                updateOnChange
                buttonText='Start Data Restore'
                onChange={ e => { return false }}
                callback={ e => {

                   setShowRestore(false);
                   context.notification.show('Your data is being restored now.', 'success', true, 'toast', 'heart');

                }}
              />
          </Fragment>         
        </Card>
      }

    
      <>

      <Card>
        <div style={{display: 'flex', paddingBottom: 20}}>
          <div style={{marginTop: '16px', paddingRight: '10px'}}>Filter:</div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            showDaysOutsideCurrentMonth={true}
            showTodayButton={true}
            //maxDateTime={new moment()}
            label="Last Updated Before:"
            value={selectedDate}
            onChange={handleDateChange}
            InputProps={{
              style: {
                color: '#707070',
              fontSize: '14px'}}}
            DialogProps={{
              style: {
                color: '#707070',
              fontSize: '14px'}}}
            renderInput={(params) => <TextField  {...params} />}
          />
          <div style={{zIndex:1000, width: '200px'}}>
          <Select
              placeholder="All Data Types"
              onChange={(item) => {selectDataType(item.value)}}
              options={dataTypeList}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (base) => ({...base, height: 53, minHeight: 53, marginRight: 20, marginLeft: 20 })
              }}
          />
          </div>
        

          <div style={{paddingLeft: '10px', marginTop: '1px'}}>
          <Button
            text='Find'
            action={ findRecs }
            params={{ id: 'btnFind' }}
            color='blue' 
          />
          </div>


          {/* <div style={{paddingLeft: '10px', marginTop: '1px'}}>
            <Button
              text='Restore...'
              action={ e => setShowRestore(true) }
              params={{ id: 'btnRestore' }}
            />
          </div> */}

          <div style={{paddingLeft: '10px', marginTop: '1px', marginLeft: 'auto'}}>
            <Button
              text='Update Now'
              action={ e => runBackup() }
              params={{ id: 'btnRestore' }}
            />
          </div>

        </LocalizationProvider>
        </div>
      <Table
        search
        data={ recordList }
        checkItems="type"
        //loading={ table.loading }
        hide={'id,checked,url'}
        hyperlink={{
          col: 'name',
          //url: '/app/DataHistory',
          //callback: loadCompany
        }}
        badge={{ col: 'plan', color: 'blue' }}
        actions={{ 
          hyperlink: setActiveRecord,
          edit: setActiveRecord, 
          selectRecord: selectRecord,
          //view: setActiveRecord, //'/dashboard' // will load this url + /rowID  
           
        }}
      >
      </Table>
      </Card>
      </>
    

    </>
  );
}



