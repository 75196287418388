import React, { useState, useContext, useEffect, useRef } from 'react';
import {useParams} from 'react-router-dom';
import { ViewContext, AuthContext, Card, useAPI, Button, Message, Form } from 'components/lib';
import { NavLink } from "react-router-dom";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import { RuleOperation } from './ruleOperation';
import styles from './styles.css'

const { v4: uuidv4 } = require('uuid');


export function RuleDetails(props){

  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const searchParams = new URLSearchParams(window.location.search);

  const {id} = useParams();
  

  const [rule, setRule] = useState(null);
  const ruleDetails = useAPI('/api/auditRule/details/'+ id);

  const [dataTypeList, setDataTypeList] = useState(null);
  var dataTypes = useAPI('/api/appData/dataTypes/');

  const formRef = useRef();

  const ruleTypeOptions = [
    { value: 'info', label: 'Information', color: '#5243AA' },
    { value: 'warning', label: 'Warning', color: '#5243AA' },
    { value: 'error', label: 'Error', color: '#5243AA' },];

  useEffect(() => {
    console.log('here - ' + JSON.stringify(ruleDetails))
    if(ruleDetails?.data) {
      const orgName = authContext.orgs?.find(x => x.id === authContext.user?.app_connection_id);
      setRule(ruleDetails.data);
    }

    if(searchParams.get('cloned')) {
      context.notification.show('Rule successfully cloned.', 'success', true);
    }

    // if(dataTypeList === undefined) {
    //   let recList = [];
    //   recList.push({
    //     value: ruleDetails.data.record_type.toLowerCase(),
    //     label: ruleDetails.data.record_type[0].toUpperCase() + ruleDetails.data.record_type.substr(1)
    //   })
    //   // setDataTypeList(recList);
    //   // setLoading(false);
    // }

  }, [ruleDetails.data]);


  useEffect(() => {
    
    let recList = [];
    if(dataTypes?.data?.length) {

      dataTypes.data.map((item) => {
        
        let [val] = item.name;
        //let data = item.data();
        recList.push({
          value: val.toLowerCase(),
          label: val
        });
      });

    }

    if(recList.length > 0) {
      setDataTypeList(recList);
    }

  }, [dataTypes.data]);

  const saveChanges = (form) => {
    let newRule = {
      ...rule,
      ...form,
    }

    var method = "PATCH";
    if((!newRule.rule_id) || (newRule.rule_id === "")) {
      method = "POST";
    }
    
    context.modal.show({
      title: 'Are you sure you want to save this monitor?',
      form: {
        rule: {
          type: 'hidden',
          value: newRule
        }
      },
      buttonText: 'Yes, Save',
      url: '/api/auditRule/' + id,
      method: method

    }, () => {

      context.modal.hide();
      context.notification.show('Rule saved.', 'success', true);

    });
  };

  const deleteRule = () => {

    if(rule.master_rule === true) {
      context.modal.show({
        title: 'System generated monitors cannot be deleted. You can mark it as inactive and it will not be run.',
        form: {},
        buttonText: 'OK',
        showCancel: false
        
      }, () => {

        context.modal.hide();

      });
    }
    else {

      context.modal.show({
        title: 'Are you sure you want to delete this monitor?',
        form: {
          data: {
            type: 'hidden',
            value: ruleDetails
          }
        },
        buttonText: 'Yes, Delete',
        url: '/api/auditRule/' + id,
        method: 'DELETE'

      }, () => {

        context.modal.hide();
        context.notification.show('Rule saved.', 'success', true);
        window.location = "/app/cleanup/rules";

      });
    }
  };

  const cloneRule = () => {
    if((authContext.hasAccountSetting("edit_rules")) === false) {
      context.modal.show({
        title: 'You do not have permission to edit monitors.',
        form: {},
        buttonText: 'OK',
        showCancel: false
        
      }, () => {

        context.modal.hide();

      });

      return;
    }

    context.modal.show({
      title: 'Are you sure you want to clone this monitor?',
      form: {},
      buttonText: 'Yes, Clone',
      url: '/api/auditRule/clone/' + id,
      method: 'POST'

    }, (form, res) => {
      if(res) {
        console.log(res)
        // setRule(res.data);

        // context.modal.hide();
        // context.notification.show('Rule successfully cloned.', 'success', true);

        window.location = "../ruledetails/" + res + "?cloned=t";
      }
    });
  };

  const cancelChanges = () => {
    
    context.modal.show({
      title: 'Are you sure you want to cancel your changes?',
      form: {},
      buttonText: 'Yes, Cancel',
      cancel: true,
      cancelText: 'Keep Editing'
  }, () => {

    window.location = "/app/cleanup/rules";

  });
}


function insertOperandAfterId(id, newOperand) {
  function insertOperand(arr, id, newOperand) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        arr.splice(i + 1, 0, newOperand);
        return true;
      } else if (arr[i].operands) {
        if (insertOperand(arr[i].operands, id, newOperand)) {
          return true;
        }
      }
    }
    return false;
  }

  if (rule.process_definition.rule && rule.process_definition.rule.operands) {
    insertOperand(rule.process_definition.rule.operands, id, newOperand);
  }
  // if there is no operand yet, then we have to add it.
  else if(rule.process_definition.rule) {
    rule.process_definition.rule = {
      id: "op1",
      expression: "and",
      operands: [
        rule.process_definition.rule,
        newOperand
      ]
    }
  }

  return rule.process_definition;
}

const OPERAND_TEMPLATE = {
  field:"",
  operator:"=",
  value:"", 
  id: null}

const addRuleCondition = (id) => {
  console.log(id)
  var newOperand = OPERAND_TEMPLATE;
  newOperand.id = uuidv4();

  var process_definition = insertOperandAfterId(id, newOperand);
  
  console.log(JSON.stringify(process_definition))
  setRule({
    ...rule,
    process_definition: process_definition
  });

  return process_definition;
}


function editExpressionById(processDefinition, id, field, value) {
  // console.log(id)
  function editExpression(obj, id) {
    console.log(obj.id)
    if (obj.id === id) {
      // Toggle the expression value
      // console.log('found exp')
      // obj.expression = obj.expression === "and" ? "or" : "and";
      obj[field] = value;
      return true;
    } else if (obj.operands) {
      for (const operand of obj.operands) {
        if (editExpression(operand, id, field, value)) {
          return true;
        }
      }
    }
    return false;
  }

  if (processDefinition) {
    editExpression(processDefinition, id, field, value);
  }

  return processDefinition;
}

const editRuleCondition = (id, field, value) => {
  if((authContext.hasAccountSetting("edit_rules")) === false) {
    return;
  }
  var process_definition_rule = editExpressionById(rule.process_definition.rule, id, field, value);
  

  if(process_definition_rule != null) {
    setRule({
      ...rule,
      process_definition: {
        ...rule.process_definition,
        rule: process_definition_rule
      }
    });
  }
}

// const editRuleCondition = (id, field, value) => {
//   console.log('id: ' + id + ", field: " + field + ", value: " + value)
  
//   function editOperand(arr, id, field, value) {
//     for (let i = 0; i < arr.length; i++) {
//       if (arr[i].id === id) {
//         console.log('found')
//         // arr.splice(i + 1, 0, newOperand);
//         arr[field] = value;
//         alert(JSON.stringify(arr))
//         return true;
//       } else if (arr[i].operands) {
//         if (editOperand(arr[i].operands, id, field, value)) {
//           return true;
//         }
//       }
//     }
//     return false;
//   }

//   if (rule.process_definition.rule) {
//     console.log(JSON.stringify(rule.process_definition.rule))
//     editOperand(rule.process_definition.rule, id, field, value);
//   }





//   var process_definition_rule = editOperand(rule.process_definition.rule, id, field, value);
  
//   console.log(JSON.stringify(process_definition_rule))

//   if(process_definition_rule != null) {
//     setRule({
//       ...rule,
//       process_definition: {
//         ...rule.process_definition,
//         rule: process_definition_rule
//       }
//     });
//   }



// }

function removeOperandById(processDefinition, id) {
  function removeOperand(obj, id) {
    if (!obj || !obj.operands) {
      return false;
    }

    for (let i = 0; i < obj.operands.length; i++) {
      if (obj.operands[i].id === id) {
        obj.operands.splice(i, 1);
        return true;
      } else if (obj.operands[i].operands) {
        if (removeOperand(obj.operands[i], id)) {
          if (obj.operands[i].operands.length === 0) {
            // If operands array is empty after removing, handle the special case
            obj.operands.splice(i, 1);
            return true;
          } else if (obj.operands[i].operands.length === 1) {
            // If there is only one operand left, move it up to the next level
            const movedOperand = obj.operands[i].operands[0];
            obj.operands.splice(i, 1, movedOperand);
            return true;
          }
          return true;
        }
      }
    }
    return false;
  }

  if (processDefinition) {
    removeOperand(processDefinition, id);

    // Move upwards if the outermost array has only one item
    if (processDefinition.operands && processDefinition.operands.length === 1) {
      const movedOperand = processDefinition.operands[0];
      return movedOperand;
    }

    // Ensure there is at least one operand left
    if (processDefinition.operands && processDefinition.operands.length === 0) {
      return null;
    }
  }

  return processDefinition;
}

const removeRuleCondition = (id) => {
  
  var process_definition_rule = removeOperandById(rule.process_definition.rule, id);
  
  // console.log(JSON.stringify(process_definition_rule))

  if(process_definition_rule != null) {
    setRule({
      ...rule,
      process_definition: {
        ...rule.process_definition,
        rule: process_definition_rule
      }
    });
  }
}

function toggleExpressionById(processDefinition, id) {
  console.log(id)
  function toggleExpression(obj, id) {
    if (obj.id === id) {
      // Toggle the expression value
      console.log('found')
      obj.expression = obj.expression === "and" ? "or" : "and";
      return true;
    } else if (obj.operands) {
      for (const operand of obj.operands) {
        if (toggleExpression(operand, id)) {
          return true;
        }
      }
    }
    return false;
  }

  if (processDefinition) {
    toggleExpression(processDefinition, id);
  }

  return processDefinition;
}

const toggleExpression = (id) => {
  if((authContext.hasAccountSetting("edit_rules")) === false) {
    return;
  }
  var process_definition_rule = toggleExpressionById(rule.process_definition.rule, id);
  
  console.log(JSON.stringify(process_definition_rule))

  if(process_definition_rule != null) {
    setRule({
      ...rule,
      process_definition: {
        ...rule.process_definition,
        rule: process_definition_rule
      }
    });
  }
}

function createRuleAroundId(json, id) {
  function findNodeAndWrap(node, id) {
    if (node.id === id) {
      // Wrap the existing node with a new rule
      const newRule = {
        id: uuidv4(),
        expression: "and",
        operands: [node],
      };

      // Add a new operand to the new rule
      const newOperand = {
        id: uuidv4(),
        field: "",
        operator: "==",
        value: "",
      };
      newRule.operands.push(newOperand);

      // Return the new rule to be added to the parent's operands array
      return newRule;
    } else if (node.operands) {
      // Recursively search for the node with the specified ID
      for (let i = 0; i < node.operands.length; i++) {
        node.operands[i] = findNodeAndWrap(node.operands[i], id);
      }
    }
    return node;
  }

  if (json && json.rule) {
    // Create the new rule and add it to the parent's operands array
    json.rule = (findNodeAndWrap(json.rule, id));
  }

  return json;
}

const addOperand = (id) => {
  var process_definition_rule = createRuleAroundId(rule.process_definition, id);
  
  console.log(JSON.stringify(process_definition_rule))

  if(process_definition_rule != null) {
    setRule({
      ...rule,
      process_definition: process_definition_rule
    });
  }
}

const toggleRuleSuccess = () => {
  if((authContext.hasAccountSetting("edit_rules")) === false) {
    return;
  }

  if(rule.master_rule === true) {
    return;
  }
  console.log(rule.success)
  setRule({
    ...rule,
    process_definition: {
      ...rule.process_definition,
      success: !rule.process_definition.success
    }
  });
}

  const onDataChange = (field) => {
    console.log(JSON.stringify(field))

    if(field.input === "record_type") {
      setRule({
        ...rule,
        record_type: field.value
      })
    }
    else if(field.input === "display_name") {
      setRule({
        ...rule,
        display_name: field.value
      })
    }
    else if(field.input === "short_description") {
      setRule({
        ...rule,
        short_description: field.value
      })
    }
    else if(field.input === "failure_type") {
      setRule({
        ...rule,
        failure_type: field.value
      })
    }
    else if(field.input === "is_active") {
      setRule({
        ...rule,
        is_active: field.value
      })
    }
    console.log(JSON.stringify(rule))
  }


  return (
    <>
      <nav className='subnav'>
      <NavLink exact to='/app/cleanup/index' activeClassName='active'>Clean-Up</NavLink>
      <NavLink exact to='/app/cleanup/rules' activeClassName='active'>Alerts</NavLink>
      </nav>

      <Card>
        
        <div style={{display: 'flex', paddingBottom: 20}}>
            <div style={{paddingLeft: '10px', marginTop: '1px', marginLeft: 'auto'}}>
            <Button text='Save Changes' small='true' className='actionBarBtnLg' action={() => formRef.current.submit()} />
            <Button text='Cancel' small='true' className='actionBarBtnLg' action={(cancelChanges)} />
            
            {authContext.hasAccountSetting("edit_rules") === true &&
            <>
            <span style={{paddingRight: '40px'}}></span>
            <Button text='Clone' small='true' className='actionBarBtnLg' action={(cloneRule)} />
            <Button text='Delete' small='true' className='actionBarBtnLg' action={(deleteRule)} />
            </>
  }
            </div>
        </div>
        
        
        {((rule?.master_rule === true) && (authContext.hasAccountSetting("edit_rules") === true)) &&
          <Message
            title='System Generated Monitor'
            text='This is a system generated monitor that cannot be edited. You can choose to make it inactive, or clone it so that you can modify it for your purposes.'
            type='warning'
          />
        }

        {rule &&
        <div className="section">


          {dataTypeList && rule &&
          <Form
            columns={["col1","col2"]}
            ref={formRef}
            data={{
              display_name: {
                label: 'Name',
                type: 'text',
                required: true,
                value: rule?.display_name,
                errorMessage: 'Please name the monitor',
                readonly: (rule?.master_rule === true) || (authContext.hasAccountSetting("edit_rules") === false)
              },
              short_description: {
                label: 'Description',
                type: 'text',
                required: true,
                value: rule?.short_description,
                errorMessage: 'Please enter a description',
                readonly: (rule?.master_rule === true) || (authContext.hasAccountSetting("edit_rules") === false)
              },
              record_type: {
                label: 'Record Type',
                type: 'select',
                default: rule.record_type,
                options: dataTypeList,
                readonly: (rule?.master_rule === true) || (authContext.hasAccountSetting("edit_rules") === false)
              },
              failure_type: {
                label: 'Rule Type',
                type: 'select',
                default: rule.failure_type,
                options: ruleTypeOptions,
                readonly: (rule?.master_rule === true) || (authContext.hasAccountSetting("edit_rules") === false)
              },
              is_active: {
                label: 'Is Active',
                type: 'switch',
                default: rule?.is_active,
                value: rule?.is_active,
              }
            }}
            updateOnChange={true}
            onChange={(field)=> onDataChange(field)}
            callback={ (form) => saveChanges(form)}
          />
          }

        </div>
        }

        {rule?.process_definition?.rule &&
          <RuleOperation 
            data={rule?.process_definition?.rule} 
            success={rule?.process_definition.success}
            addRuleCondition={addRuleCondition}
            removeRuleCondition={removeRuleCondition}
            toggleExpression={toggleExpression}
            addOperand={addOperand}
            toggleRuleSuccess={toggleRuleSuccess}
            editRuleCondition={editRuleCondition}
            recordType={rule?.record_type}
         />
        }
      </Card>
    </>
    );
}
