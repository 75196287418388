/***
*
*   SOCIAL SIGN IN BUTTONS
*   To sign up/in with Google or Microsoft
*
**********/

import React, { useState } from 'react';

import { Button } from 'components/lib';
import Style from './social.module.scss';

export function SocialSignin(props){

  const [loading, setLoading] = useState(props.network?.map(x => { return { [x]: false }}));
  
  // construct query string
  let qs = '';
  if (props.invite) qs = `?invite=${props.invite}`;
  if (props.signup) qs = '?signup=1'

  return (
    <div className={ Style.signinButtons }>
      { props.network?.map(n => {
        
        if(n === "intuit") {
          return(
            <div style={{paddingBottom: 10, paddingLeft: 90}}>
            <a href="/auth/intuit"><img src='/images/Sign_in_blue_btn_short_default2x.png' alt="" /></a> 
            </div>
          )
        }
        else {
          return (
            <Button  
              key={ n }
              loading={ loading[n] }
              icon={['fab', n]}
              iconPack='fontawesome'
              iconSize='1x'
              iconButton
              className={ Style[n] }
              action={ () => setLoading({ [n]: true }) }
              url={ `/auth/${n}${qs}` }
              text={ `Continue with ${n.charAt(0).toUpperCase() + n.slice(1)}` }
            />
          )
        }
      })}

      { props.showOr && 
        <span className={ Style.or }>or</span> }

    </div>
  );
}
