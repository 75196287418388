import React, { useState, useContext, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import { AuthContext, Card, useAPI, Button, Badge } from 'components/lib';
import { Table } from 'components/lib';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { NavLink } from "react-router-dom";
import { use } from 'chai';


export function RestoreDetails(props){
  
  const [recordList, setRecordList] = useState([]);
  const [settings, setSettings] = useState(null);
  const {id} = useParams();

  var records = useAPI('/api/appData/restoreDetails/' + id)
  
  const updateRecordList = (restore_evt) => {
    // console.log(JSON.stringify(restore_evt))
    setSettings(restore_evt?.settings)
    if(restore_evt?.records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      restore_evt.records.map((item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          checked: false,
          type: item.record_type,
          name: item.name,
          Last_Updated: item.last_updated_at,
          // issue: item.short_description,
          status: item.status
          //recDetails: data.recDetails
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records.data)

  }, [records.data]);
  

  const setActiveRecord = (rec, index) => {
    window.location = '/app/activity/details/'+rec.id;
  };
  

  return (
    <>
      <nav className='subnav'>

        <NavLink exact to='/app/activity/index' activeClassName='active'>Activity</NavLink>
        <NavLink exact to='/app/activity/restore' activeClassName='active'>Data Restore</NavLink>
        <NavLink exact to='/app/activity/restoreHistory' activeClassName='active'>Restore History</NavLink>
        <NavLink to='/app/activity/restoreDetails' activeClassName='active'>Restore Details</NavLink>
      </nav>
   
      <>

      <Card>
        <div className="section">
          <div className="form-group" style={{paddingBottom: '16px'}}>
            <label className="label" style={{width: '120px', display: 'inline-block'}}>
              Restored At:
            </label>
            {settings?.created_at}
          </div>


          <h3>Settings Used</h3>
        
          <div className="form-group" style={{paddingBottom: '8px'}}>
            <label className="label" style={{width: '120px', display: 'inline-block'}}>
              Restore To:
            </label>
            {settings?.parameters?.dt}
          </div>

          <div className="form-group" style={{paddingBottom: '8px'}}>
            <label className="label" style={{width: '120px', display: 'inline-block'}}>
               Record Type(s):
            </label>
            {settings?.parameters?.rec_types}
          </div>

          <div className="form-group" style={{paddingBottom: '8px'}}>
            <label className="label" style={{width: '120px', display: 'inline-block'}}>
               Record ID(s):
            </label>
            {settings?.parameters?.rec_ids}
          </div>

          <div className="form-group" style={{paddingBottom: '8px'}}>
            <label className="label" style={{width: '120px', display: 'inline-block'}}>
              Status:
            </label>
            <Badge color={settings?.status === 'complete' ? 'blue' : 'orange'} text={settings?.status} /> 
          </div>

        </div>
        <div style={{display: 'flex', paddingBottom: 20}}>
          {/* <div style={{marginTop: '16px', paddingRight: '10px'}}>Filter:</div> */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* <div style={{zIndex:1000}}>
            <Select
                placeholder="Data Types"
                onChange={(item) => {selectDataType(item.value)}}
                options={dataTypeList}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  control: (base) => ({...base, height: 53, minHeight: 53, marginRight: 20, marginLeft: 20 })
                }}
            />
            </div> */}
        

          {/* <Button
            text='Find'
            action={ findRecs }
            params={{ id: 'btnFind' }}
          /> */}

         
        </LocalizationProvider>
        </div>
      <Table
        search
        data={ recordList }
        checkItems="type"
        //loading={ table.loading }
        hide={'id,checked'}
        hyperlink={{
          col: 'name',
          //url: '/app/DataHistory',
          //callback: loadCompany
        }}
        badge={{ col: 'status', color: 'blue', condition: [

          { value: 'warning', color: 'yellow' },
          { value: 'error', color: 'red' }
        
        ]}}
        actions={{ 
          hyperlink: setActiveRecord,
          edit: setActiveRecord, 
          // selectRecord: selectRecord,
          //view: setActiveRecord, //'/dashboard' // will load this url + /rowID  
           
        }}
      >
      </Table>
      </Card>
      </>
    
    </>
  );
}
