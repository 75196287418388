import React, { useContext } from 'react';
import {useParams} from 'react-router-dom';
import { AuthContext } from 'components/lib';
import { NavLink } from "react-router-dom";
import RecordDetails from "../RecordDetails";

export function Details(props){

  const {id} = useParams();

  // const [connections, setConnections] = useState([]);
  // const [realms, setRealms] = useState([]);
  // const list = useAPI('/api/app_connection');
  
  return (
    <>
    <nav className='subnav'>

    <NavLink exact to='/app/activity/index' activeClassName='active'>Activity</NavLink>
    <NavLink  to='/app/activity/details' activeClassName='active'>Details</NavLink>
    </nav>

        {/* <Breadcrumbs items={[
          { name: 'Activity', url: '/app/activity/index' },
          { name: 'Record Details', url: '#' }
        ]}/> */}
        <RecordDetails id={id} />
       

    </>
  );
}



