import { App_connection } from 'views/app/app_connection';
import { Help } from 'views/dashboard/help';
import { OrganizationList } from 'views/firm/OrganizationList';

const Routes = [
  {
    path: '/help',
    view: Help,
    layout: 'firm',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/firm/organization_list',
    view: OrganizationList,
    layout: 'firm',
    permission: 'user',
    title: 'Clients'
  },
  {
    path: '/firm/app_connection',
    view: App_connection,
    layout: 'firm',
    permission: 'user',
    title: 'Connections'
  }
]

export default Routes;
