/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { useState, useEffect } from 'react';
import { Card, Chart, Table, Grid, Animate, Feedback, useAPI, Message } from 'components/lib';
import GaugeChart from 'react-gauge-chart'

export function Dashboard(props){

  const appConnections = useAPI('/api/app_connection');

  const dataSummary = useAPI('/api/appData/dataProcessedChart');
  const auditData = useAPI('/api/auditRule/resultsSummary');
  const backupData = useAPI('/api/appData/dataProcessedSummary');
  const healthScore = useAPI('/api/appData/healthScore');
  const connectionSummary = useAPI('/api/appData/connectionSummary');
  
  const [connSummary, setConnSummary] = useState(null);
  const [connSummaryTitle, setConnSummaryTitle] = useState("");
  const [dataFlowing, setDataFlowing] = useState(true);


  useEffect(() => {
    console.log(JSON.stringify(connectionSummary))
    setConnSummary(connectionSummary.data)

    var title = 'No data flowing from system yet. Please wait.';

    if(connectionSummary?.data) {
      if(connectionSummary?.data?.co_name !== "") {
        title = connectionSummary.data?.co_name + ' is connected to ' + connectionSummary.data?.app_name
      }

      if(connectionSummary?.data?.notif !== "") {
        title = title + " with alerts sent to " + connectionSummary.data?.notif;
      }

      setDataFlowing(true);
    } else {
      setDataFlowing(false);
    }
    setConnSummaryTitle(title);

  }, [connectionSummary]);

  return (
    <Animate type='pop'>

{appConnections.data?.length === 0 ? 
  <Card 
        
        loading={appConnections?.loading}
      >
        <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2>Setup your first application data connection to begin using LedgerView</h2>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2><a href='/app/app_connection'>App Connections</a></h2>
    </div>
  </Card>

: 

dataFlowing === false ? 
  <Card 
        
        loading={appConnections?.loading}
      >
        <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2>No data is detected yet. LedgerView will track changes as they happen in your connected apps.</h2>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2><a href='/app/app_connection'>App Connections</a></h2>
    </div>
  </Card>

:

<>
{connectionSummary.loading === false &&
      <Message
        title={connSummaryTitle}
        text={connSummary != null ? ('Last Data Seen At: ' + connSummary?.last_updated_at) : ''}
        type={connSummary != null ? 'success' : 'warning'}
        >
      </Message>
      }
    <div style={{display: 'flex'}}>
      <div style={{width:'66%', marginRight: '2%', marginBottom: '40px'}}>
      
      

      

      <Card name='history'>
        <Chart
          type='line'
          legend
          loading={ dataSummary.loading }
          data={ dataSummary.data }
          color={['blue', 'green', 'red']}
        />
      </Card>
      </div>
      <div style={{width:'32%'}}>
        <Card title='Health Score' loading={appConnections?.loading} center={true}>
          <div  style={{minHeight: '183px'}}>
          <GaugeChart id="gauge-chart5"
            nrOfLevels={100}
            arcsLength={[0.3, 0.5, 0.2]}
            colors={['#EA4228', '#F5CD19', '#5BE12C']}
            percent={healthScore.data}
            arcPadding={0.02}
            textColor='#000000'
          />
          {/* <HorizontalBar
          loading={ backupData?.loading }
          data={ backupData?.data }
          color={['red', 'blue']}
        /> */}
          </div>
        </Card>
        </div>
      </div>
      <Grid cols='2'>
        <Card
          title='Clean-Up'
          loading={appConnections?.loading}
          minHeight='370px'
          hyperlink="/app/cleanup/index"
        >
          {/* <Chart
            type='donut'
            legend={ true }
            data={ auditData?.data }
            loading={ auditData?.loading }
          /> */}

          <Table
            data={ auditData?.data }
            loading={ appConnections?.loading }
            search={false}
            sort={true}
            show={['rule', 'warnings', 'errors']}
            hyperlink={{
              col: 'id',
              url: '/app/DataHistory',
              //callback: loadCompany
            }}
          >
          </Table>
        </Card>

        <Card
          title='Activity'
          subtitle='last 30 days'
          loading={backupData?.loading}
          minHeight='370px'
          hyperlink="/app/activity/index"
        >
          <Chart
          type='bar'
          loading={ backupData?.loading }
          data={ backupData?.data }
          color={['green']}
        />
          {/* <Table
            data={ backupData?.data }
            loading={ backupData?.loading }
            search={false}
            sort={true}
            show={['record_type', 'num']}
            hyperlink={{
              col: 'id',
              url: '/app/DataHistory',
              //callback: loadCompany
            }}
          >
          </Table> */}
        </Card>

      </Grid>
      </>
}

{/* 
      <Grid cols='3'>
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.users }
          label='users'
          icon='users'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.active }
          label='active'
          icon='check'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.churned }
          label='churned'
          icon='refresh-cw'
        />
        
      </Grid>

       */}
{/* 
      <Grid cols='2'>
        <Card title='Goals' loading={ progress.loading }>
          { progress?.data?.map(item => {

            return (
              <ProgressBar
                key={ item.label }
                label={ item.label }
                progress={ item.value }
              />
            );

          })}
        </Card>
        <Card title='User Types'>
          <Chart
            type='pie'
            legend={ true }
            data={ userChart.data }
            loading={ userChart.loading }
            color='purple'
          />
        </Card>
      </Grid>

      <Card title='Users'>
        <Table
          search
          data={ table.data }
          loading={ table.loading }
          badge={{ col: 'plan', color: 'blue' }}>
        </Table>
      </Card> */}

      <Feedback />

    </Animate>
  );
}
