/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext, useState } from 'react';
import { AuthContext, ViewContext, AppNav, Header, User } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals
import IdleTimeOutHandler from '../idleTImeoutHandler';

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  var items=[
      { label: 'Dashboard', icon: 'home', link: '/dashboard' },
      { label: 'Activity', icon: 'activity', link: '/app/activity/index',
        submenu: [
          { label: 'Logs', icon: 'clipboard', link: '/app/activity/index'},
          { label: 'Clean-Up', icon: 'check', link: '/app/cleanup/index'},
        ]
      },
      { label: 'Monitors', icon: 'bell', link: '/app/cleanup/rules'},
      { label: 'Restore', icon: 'corner-up-left', link: '/app/activity/restore'},
      // { label: 'Explore', icon: 'command', link: '/app/explore/index' },
      { label: 'AI Assistant', icon: 'aperture', link: '/ai' },
      { label: 'Connections', icon: 'link-2', link: '/app/app_connection' },
    ]

    if((!context.user.account_settings) || (context.user.account_settings?.dev === false)) {
      
      // remove the explore and AI menu items
      items.splice(3, 2);

      // remove the Activity menu item
      // items.splice(1, 1);
      // items.splice(2, 0, { label: 'Rules', icon: 'activity', link: '/app/cleanup/rules' });
    }

    if(context.user.account_settings?.firm === true) {
      items.unshift({ label: 'Clients', icon: 'compass', link: '/firm/organization_list' })
    }

  const[isActive,setIsActive]=useState(true)
  const onLogout = () => {
  context.signout();
    //alert('here')
  }
  return (
    <Fragment>
      <IdleTimeOutHandler 
        onActive={()=>{setIsActive(true)}} 
        onIdle={()=>{onLogout()}}
        // onLogout={()=>{alert('here')}}
        />

  {/* <p>
      {isLogout?'Please reload to Login':
      isActive?'Hello There':'Interact to be active'
    }
    </p> */}

      <AppNav
        type='popup'
        items={items}
          
        bottomItems={[
          { label: 'Account', icon: 'user', link: '/account', },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title }>
          <User/>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
