import React from 'react'
import { Doughnut } from 'react-chartjs-2';

export function DonutChart(props) {

  let options = {...props.options };
  options.responsive = true;
  options.maintainAspectRatio = false;
  options.scales.yAxes[0].ticks = { display: false };
  options.scales.xAxes[0].ticks = { display: false };
  options.scales.xAxes[0] = { display: false };
  options.scales.yAxes[0] = { display: false };

  return <Doughnut data={ props.data } options={ options }  />

}
