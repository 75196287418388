import React, {  useState, useContext, useEffect, useRef } from 'react';
import { AuthContext, Card, useAPI, Grid } from 'components/lib';
import { NavLink } from "react-router-dom";
import axios from 'axios';

import { MessageList } from "react-chat-elements"
import "react-chat-elements/dist/main.css"
import { FileUpload } from 'primereact/fileupload';
import Markdown from 'markdown-to-jsx'

export function AiAgents(props){

  const [analysisDetails, setAnalysisDetails] = useState({});
  const authContext = useContext(AuthContext);
  
  const appCategories = [
    {
      name: "Data Analysis",
      code: "data"
    },
    {
      name: "Workflow",
      code: "workflow"
    }
  ]

  const apps = [
    {
      name: 'Competitive Analysis',
      short_name: 'qbo',
      type: 'data',
      icon: '',
      coming_soon: true,
      prefix: 'Company',
      description: "Connecting QuickBooks Online enables real-time data monitoring. LedgerView will begin by retrieving the last 30 days of transaction details and process each of them. It will flag all rules violations and show them in the LedgerView dashboard. Once completed, LedgerView will be alerted to any changes within QuickBooks and, in real-time, will process these changes.\n\nLedgerView will also retrieve and process all list data, including\n\n* Customers\n* Vendors\n* Employees\n* Products\n* Chart of Accounts\n"
    },
    {
      name: 'Cashflow Dashboard',
      short_name: 'qbo',
      type: 'data',
      icon: '',
      coming_soon: true,
      prefix: 'Company',
      description: "Connecting QuickBooks Online enables real-time data monitoring. LedgerView will begin by retrieving the last 30 days of transaction details and process each of them. It will flag all rules violations and show them in the LedgerView dashboard. Once completed, LedgerView will be alerted to any changes within QuickBooks and, in real-time, will process these changes.\n\nLedgerView will also retrieve and process all list data, including\n\n* Customers\n* Vendors\n* Employees\n* Products\n* Chart of Accounts\n"
    },
    {
      name: 'Invoice Creator',
      short_name: 'xero',
      type: 'workflow',
      icon: '',
      coming_soon: true
    },
    
  ]

  const connectApp = (short_name, conn_id) => {
  }

  return (
    <>
      <nav className='subnav'>
      <NavLink exact to='/ai' activeClassName='active'>Chat</NavLink>
      <NavLink exact to='/ai/agents' activeClassName='active'>Agents</NavLink>
      </nav>

        <div style={{ marginBottom: '40px'}}>
        <Card minHeight='388px'>
            <div style={{paddingBottom: 20}}>
                <div style={{marginTop: '16px', paddingRight: '10px', fontSize: '20px'}}>We're working on some new AI agents which help assist you in running your business.</div>
                
            </div>
        
            {appCategories.map((cat) => {
    return(
      <>
  
  <h1 style={{paddingTop: 40, paddingBottom: 10}}>{cat.name}</h1>
  
  <Grid cols="3">
  { apps.filter(app => app.type===cat.code).map((item) => {
    var activeConn = undefined
    return(
      
      <Card highlight={true}>
        <div style={{display: 'flex', flexDirection: 'row', minHeight: '100px'}}>
          {item.icon && <img style={{height: 40, width: 40}} src={item.icon} alt="" />}
          <div style={{paddingTop: '6px', fontSize: '20px', fontWeight: 'bold', paddingLeft: '20px', width: '70%'}}>{item.name}</div>
          
          {activeConn === undefined ? 
          <div style={{minHeight: 30, paddingTop: '10px', width: '120px'}}>
            {item.coming_soon ? 'Coming soon' : 
              // item.short_name === 'qbo' ?
              // <a href="#" onClick={() => connectApp(item.short_name)}><img style={{height: 30}} src='/images/C2QB_green_btn_short_default_2x.png' alt="" /></a> :
              <a href="#" onClick={() => connectApp(item.short_name)}>Connect</a>}
          </div>
          :
          <div style={{minHeight: 30, paddingTop: '10px'}}>
            <a href="#" onClick={() => connectApp(item.short_name, activeConn.id)}>Settings</a>
            {/* {
              activeConn.edit_settings === true &&
              <Button color='#707070' icon='settings' action={() => editSettings(activeConn)} />
            } */}
          </div>
        }
        </div>
      </Card>
    )})}
</Grid>
</>
    );

})
}

              </Card>
        </div>
      
      

<div>

</div>

    
    </>
  );
}



