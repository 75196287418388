import React, { useState, useContext, useEffect } from 'react';
import { AuthContext, Card, useAPI } from 'components/lib';
import { NavLink } from "react-router-dom";
import GaugeChart from 'react-gauge-chart'

import "react-chat-elements/dist/main.css"

export function Analysis(props){

  const [analysisDetails, setAnalysisDetails] = useState({});
  
  
  const authContext = useContext(AuthContext);
    
  // const [connections, setConnections] = useState([]);
  // const [realms, setRealms] = useState([]);
  // const list = useAPI('/api/app_connection');
  var analysis = useAPI('/api/appData/analysis/' + authContext.user.appConnection)
  const appConnections = useAPI('/api/app_connection');
const healthScore = useAPI('/api/appData/healthScore');

  useEffect(() => {
    if(analysis.data){
        // alert(JSON.stringify(analysis.data[0]))
        setAnalysisDetails(analysis.data[0])
    }

  }, [analysis]);

  return (
    <>
    <nav className='subnav'>

    <NavLink exact to='/app/activity/index' activeClassName='active'>Activity</NavLink>
    <NavLink exact to='/app/activity/restore' activeClassName='active'>Data Restore</NavLink>
    <NavLink exact to='/app/activity/restoreHistory' activeClassName='active'>Restore History</NavLink>
    </nav>
      <>
      

      <div style={{display: 'flex'}}>
        <div style={{width:'66%', marginRight: '2%', marginBottom: '40px'}}>
        <Card minHeight='388px'>
            <div style={{paddingBottom: 20}}>
                <div style={{marginTop: '16px', paddingRight: '10px', fontSize: '20px'}}>The following is an AI-based analysis that was performed on this business:</div>
                {/* <div style={{paddingBottom: '12px'}}><b>Date Analyzed: {analysisDetails.date}</b></div> */}

                <div>
                    <div style={{paddingBottom: '12px'}}>
                        <div><b>Summary:</b></div>
                        <div>{analysisDetails.details?.summary}</div>
                    </div>

                    <div style={{paddingBottom: '12px'}}>
                        <div><b>Potential Risks:</b></div>
                        {analysisDetails.details?.risks?.map((item, index) => {
                            return(<div> {index+1}. {item.risk.text}</div>)
                        })}
                    </div>

                    <div style={{paddingBottom: '12px'}}>
                        <div><b>Positives:</b></div>
                        {analysisDetails.details?.positives?.map((item, index) => {
                            return(<div> {index+1}. {item.positive.text}</div>)
                        })}
                    </div>
                </div>
            </div>
        
        </Card>
        </div>
      <div style={{width:'32%'}}>
        <Card title='Health Score' loading={appConnections?.loading} center={true}>
          <GaugeChart id="gauge-chart5"
           style={{height: '260px'}}
            nrOfLevels={100}
            arcsLength={[0.3, 0.5, 0.2]}
            colors={['#EA4228', '#F5CD19', '#5BE12C']}
            percent={healthScore.data}
            arcPadding={0.02}
            textColor='#000000'
          />
        </Card>
        </div>
      </div>

<div>
{/* <Card title='Chat' loading={appConnections?.loading} center={true}>
<MessageList
    className='message-list'
    lockable={true}
    toBottomHeight={'100%'}
    dataSource={[
    ]}
/>

<Input
  placeholder="Type here..."
  multiline={true}
  rightButtons={<Button text='Send' 
  // onClick={() => alert('here')}
   />}
/>
</Card> */}

</div>

      </>
    
    </>
  );
}



