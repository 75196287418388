/***
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib';

export function Signup(props){

  const context = useContext(AuthContext);
  
  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={ false } restrictWidth center>
        
      {/* <Fragment>
        <Form 
            data={{
              comment: {
              label: `Thank you for your interest in LedgerView. Our user registration will be live soon. To be notified when LedgerView is ready just give us your e-mail address and some information about yourself.`,
              type: 'textarea',
            }
            }}
            method='POST'
            url='/api/feedback/betasignup'
            updateOnChange
            buttonText='I am interested'
            onChange={ e => { return false }}
            callback={ e => {

              viewContext.notification.show('Thank you for your interest. We will be in touch soon.', 'success', true, 'toast', 'heart');

            }}
          />
          </Fragment> */}

          <SocialSignin network={['intuit', 'google']} showOr signup />

          <Form
            data={{
              name: {
                label: 'First Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name'
              },
              email: {
                label: 'Email',
                value: '',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
            }}
            url='/api/account'
            method='POST'
            buttonText='Create Account'
            callback={ res => context.signin(res) }/>

          <span>Already registered? <Link url='/signin' text='Sign In' /></span>

        </Card>
      </Row>
    </Animate>
  );
}
